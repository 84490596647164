import React, { Component } from "react";

import { connect } from "react-redux";
import {
  calcularPonderacionGeneral,
  getProductoNopoa,
  updateProductoNopoa,
} from "../../redux/actions/productos-actions";
import {
  getEjesEstrategicos,
  getEstrategias,
  getObjetivosEstrategicos,
} from "../../redux/actions/planesEstrategicos-actions";
import { getInvolucrados } from "../../redux/actions/involucrados-actions";
import {
  getAreasOrganizacionales,
  getAreasOrganizacionalesResponsables,
} from "../../redux/actions/areas-actions";
import {
  EjeEstrategico,
  ObjetivoEstrategico,
  Estrategia,
} from "./PlanesEstrategicos";
import { getProductoProgramatica } from "../../redux/actions/productoProgramatica-actions";
import { getPonderacionArea } from "./../../redux/actions/ponderacion-areas-action";
import { Authorization } from "./../../utils/Authorization";
import { LOADING_PRODUCTO_NO_POA } from "../../redux/types";
import { Multiselect } from "react-widgets";
import ReactTooltip from "react-tooltip";
import { Container } from "reactstrap";
import swal from "sweetalert";
import Loading from "../utils/Loading";

class EditarProductoNopoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: false,
      ejesEstrategicos: [],
      objetivosEstrategicos: [],
      estrategias: [],
      productoEdit: true,
      resultadoEdit: true,
      indicadorEdit: true,
      metaEdit: false,
      ponderacionAreaEdit: true,
      ejeEstrategicoEdit: true,
      objetivoEstrategicoEdit: true,
      estrategiaEdit: true,
      unidadResponsableEdit: true,
      involucradoEdit: true,
      cooperacionExternaEdit: true,
      validarTotalPonderacion: false,
      validarTotalPonderacionGeneral: false,
      snipEdit: true,
      donanteEdit: true,
      mostrarOcultarCodigoSnip: null,
      productoProgramaticaEdit: true,
      productoProgramaticaProductos: []
    };
  }

  productoRef = React.createRef();
  resultadoRef = React.createRef();
  indicadorRef = React.createRef();
  metaRef = React.createRef();
  involucradosRef = React.createRef();
  productoProgramaticaRef = React.createRef();
  unidadResponsableRef = React.createRef();

  componentDidMount() {
    const { productoNoPoaId } = this.props.match.params;

    const { roles } = this.props.user;

    // Validar que el usuario posea al menos estos tres roles pertenecientes a planificación
    // Gestor del POA
    // Analista del POA
    // Administrador general

    for (let r = 0; r < roles.length; r++) {
      if (
        roles[r] === "Gestor del POA" ||
        roles[r] === "Analista del POA" ||
        roles[r] === "Administrador general"
      ) {
        this.setState({
          snipEdit: false,
          productoProgramaticaEdit: false,
        });
        break;
      }
    }

    this.loading();
    this.setState({ isLoadingState: true });
    this.cargarData(productoNoPoaId);

    const {
      ejesEstrategicos,
      objetivosEstrategicos,
      estrategias,
    } = this.props.planesEstrategicos;
    this.prueba(ejesEstrategicos, objetivosEstrategicos, estrategias);
  }

  loading = () => async (dispatch) => {
    dispatch({ type: LOADING_PRODUCTO_NO_POA });
  };

  cargarData = async (productoNoPoaId) => {
    await this.props.getProductoNopoa(productoNoPoaId);
    await this.props.getProductoProgramatica();
    await this.props.getInvolucrados();
    await this.props.getAreasOrganizacionalesResponsables();
    this.validarInput();
    this.ActivarCamposEdit();
    this.setState({ isLoadingState: false });
    this.setState({ productoProgramaticaProductos: this.props.productoProgramaticas.find( x => x.productoNoPoaId == this.props.productoNopoa.programaticaId)});
  };

  validarInput = () => {
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  };

  editarProductoNopoa = (e) => {
    e.preventDefault();
    if (
      this.validarProducto(
        this.productoRef.current.value,
        this.resultadoRef.current.value,
        this.indicadorRef.current.value,
        this.metaRef.current.value,
        this.involucradosRef,
        this.productoProgramaticaRef
      )
    ) {
      const { productoNoPoaId } = this.props.match.params;
      let programatica= this.productoProgramaticaRef.length > 0 ? this.productoProgramaticaRef[0].programatica : null;
      let programaticaId= this.productoProgramaticaRef.length > 0 ? this.productoProgramaticaRef[0].id : null;
      let producto = {
        productoNoPoaId: productoNoPoaId,
        descripcion: this.productoRef.current.value,
        resultado: this.resultadoRef.current.value,
        indicador: this.indicadorRef.current.value,
        meta: this.metaRef.current.value,
        programatica: programatica,
        programaticaId: programaticaId,
        unidadResponsables: this.unidadResponsableRef,
        involucrados: this.involucradosRef,
        usuarioId: 2,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
      };

      this.props.updateProductoNopoa(producto)
        .then((data) => {
          this.props.match.params.codigoSnip
            ? this.props.history.push("/admin/administracion/productosNopoa")
            : this.props.history.goBack();
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  validarProducto = (
    producto,
    resultado,
    indicador,
    meta,
    involucrados,
  ) => {
    if (producto === "") {
      swal(
        "Editar Producto",
        "El campo producto es un valor requerido",
        "error"
      );
      return false;
    }
    if (resultado === "") {
      swal(
        "Editar Producto",
        "El campo resultado es un valor requerido",
        "error"
      );
      return false;
    }
    if (indicador === "") {
      swal(
        "Editar Producto",
        "El campo indicador es un valor requerido",
        "error"
      );

      return false;
    }
    if (meta === "") {
      swal("Editar Producto", "El campo meta es un valor requerido", "error");

      return false;
    }

    return true;
  };

  tieneSnip = () => {
    const snipValue = this.snipRef.current.value;
    const existeSnip = snipValue === "true";

    // Limpiar el campo codigo SNIP si el usuario elige "No"
    if (snipValue === "false") {
      this.codigoSnipRef.current.value = "";
    }

    this.setState({ mostrarOcultarCodigoSnip: !existeSnip });
  };

  calcularPonderacionArea = () => {
    if (this.ponderacionGeneralRef.current !== null) {
      this.ponderacionGeneralRef.current.value =
        this.ponderacionAreaRef.current.value *
        (this.props.ponderacionArea.ponderacion / 100);
    }
  };

  validarNotaProductosActual = (notaProductos, areaOrganizacional, user) => {
    let productoEdit = true;
    let resultadoEdit = true;
    let indicadorEdit = true;
    let metaEdit = true;
    let ponderacionAreaEdit = true;
    let ejeEstrategicoEdit = true;
    let objetivoEstrategicoEdit = true;
    let estrategiaEdit = true;
    let unidadResponsableEdit = true;
    let involucradoEdit = true;
    let cooperacionExternaEdit = true;
    let accionMitigacionEdit = true;
    let riesgoAsociadoEdit = true;
    let codigoSnipEdit = true;
    let donanteEdit = true;
    let productoProgramaticaEdit = true;

    const indice = notaProductos.length - 1;
    if (
      areaOrganizacional.areaOrganizacionalId ===
        user.areaOrganizacional.areaOrganizacionalId
    ) {
      const lastNota = notaProductos[indice];

      if (lastNota.descripcion !== null) productoEdit = false;
      if (lastNota.resultado !== null) resultadoEdit = false;
      if (lastNota.indicador !== null) indicadorEdit = false;
      if (lastNota.meta !== null) metaEdit = false;
      if (lastNota.ponderacionArea !== null) ponderacionAreaEdit = false;
      if (lastNota.ejeEstrategico !== null) ejeEstrategicoEdit = false;
      if (lastNota.cooperacionExterna !== null) cooperacionExternaEdit = false;
      if (lastNota.objetivoEstrategico !== null)
        objetivoEstrategicoEdit = false;
      if (lastNota.estrategia !== null) estrategiaEdit = false;
      if (lastNota.unidadResponsable !== null) unidadResponsableEdit = false;
      if (lastNota.involucrados !== null) involucradoEdit = false;
      if (lastNota.accionMitigacion !== null) accionMitigacionEdit = false;
      if (lastNota.riesgoAsociado !== null) riesgoAsociadoEdit = false;
      if (lastNota.codigoSnip !== null) codigoSnipEdit = false;
    }

    this.setState({
      productoEdit,
      resultadoEdit,
      indicadorEdit,
      metaEdit,
      ponderacionAreaEdit,
      ejeEstrategicoEdit,
      objetivoEstrategicoEdit,
      estrategiaEdit,
      unidadResponsableEdit,
      involucradoEdit,
      cooperacionExternaEdit,
      riesgoAsociadoEdit,
      accionMitigacionEdit,
      codigoSnipEdit,
    });
  };

  ActivarCamposEdit = () => {
    let { notaProductos, modoEditar, areaOrganizacional } = this.props.productoNopoa;

    if (modoEditar) {
      this.validarNotaProductosActual(
        notaProductos,
        areaOrganizacional,
        this.props.user
      );
    } else {
      if (this.props.match.params.codigoSnip) {
        this.setState({
          productoEdit: true,
          resultadoEdit: true,
          indicadorEdit: true,
          metaEdit: true,
          ponderacionAreaEdit: true,
          ejeEstrategicoEdit: true,
          objetivoEstrategicoEdit: true,
          estrategiaEdit: true,
          unidadResponsableEdit: true,
          involucradoEdit: true,
          cooperacionExternaEdit: true,
          accionMitigacionEdit: true,
          riesgoAsociadoEdit: true,
          donanteEdit: true,
        });
      } else {
        this.setState({
          productoEdit: false,
          resultadoEdit: false,
          indicadorEdit: false,
          metaEdit: false,
          ponderacionAreaEdit: false,
          ejeEstrategicoEdit: false,
          objetivoEstrategicoEdit: false,
          estrategiaEdit: false,
          unidadResponsableEdit: false,
          involucradoEdit: false,
          cooperacionExternaEdit: false,
          accionMitigacionEdit: false,
          riesgoAsociadoEdit: false,
          donanteEdit: false,
        });
      }
    }
  };
  
  prueba = (ejesEstrategicos, objetivosEstrategicos, estrategias) => {
    if (
      this.props.planesEstrategicos.objetivosEstrategicos.length > 0 &&
      this.state.objetivosEstrategicos.length === 0
    ) {
      this.setState(
        { ejesEstrategicos, objetivosEstrategicos, estrategias },
        () => {
          if (
            ejesEstrategicos.length === 1 &&
            this.state.ejesEstrategicos.length > 0
          ) {
            this.setState(
              {
                objetivosEstrategicos: this.state.ejesEstrategicos[0]
                  .objetivosEstrategicos,
              },
              () => {
                if (this.state.objetivosEstrategicos.length === 1) {
                  this.setState({
                    estrategias: this.state.objetivosEstrategicos[0]
                      .estrategias,
                  });
                }
              }
            );
          }
        }
      );
    }
  };

  calcularPonderacionTotalProductos = () => {
    let ponderacionAreaProps =
      this.props.totalPonderacionProductosNopoa -
      this.props.productoNopoa.ponderacionArea;
    let ponderacionArea =
      this.ponderacionAreaRef.current !== null &&
      this.ponderacionAreaRef.current.value !== ""
        ? this.ponderacionAreaRef.current.value
        : 0;
    let total = parseFloat(ponderacionAreaProps) + parseFloat(ponderacionArea);
    if (total <= 100) {
      this.setState({ validarTotalPonderacion: true });
    } else {
      this.setState({ validarTotalPonderacion: false });
      swal(
        "Editar Producto",
        "Excede el límite de ponderación permitido para los productos, actualmente tiene " +
          total +
          "%",
        "error"
      );
    }
  };

  render() {
    const {
      involucrados,
      areasOrganizacionales,
      productoProgramaticas,
      isLoading,
    } = this.props;

    const {
      descripcion,
      resultado,
      indicador,
      meta,
      ponderacionArea,
      ejeEstrategico,
      estrategia,
      objetivoEstrategico,
      unidadResponsableProductosNoPoa,
      involucradosProductos,
      ponderacionGeneral,
      notaProductos,
      accionMitigacion,
      riesgoAsociado,
      snip,
      codigoSnip,
      programatica,
      programaticaId
    } = this.props.productoNopoa;

   // console.log('en editar',this.props.productoNopoa)

    return (
      <div className="pb-6 pt-5 pt-md-7">
        { isLoading || this.state.isLoadingState ? (
          <Loading></Loading>
        ) : (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.editarProductoNopoa}
                noValidate
              >
                <div className="row">
                  <div className="form-group input-group-sm col-md-12 ">
                    <div>
                      <label htmlFor="unidadResponsable">
                        Unidad Responsable
                      </label>
                    </div>
                    <Multiselect
                      readOnly={this.state.unidadResponsableEdit}
                      placeholder="--Seleccione las unidades responsables--"
                      data={areasOrganizacionales}
                      valueField="areaOrganizacionalId"
                      id="unidadResponsable"
                      textField="nombre"
                      defaultValue={unidadResponsableProductosNoPoa}
                      onChange={(e) => {
                        this.unidadResponsableRef = [];
                        e.map((area) =>
                          this.unidadResponsableRef.push(
                            area.areaOrganizacionalId
                          )
                        );
                      }}
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <div>
                    <label className="" htmlFor="descripción">
                      Producto
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    ref={this.productoRef}
                    defaultValue={descripcion}
                    required
                    readOnly={this.state.productoEdit}
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    <label htmlFor="resultado">Resultado (s)</label>
                  </div>
                  <textarea
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    ref={this.resultadoRef}
                    defaultValue={resultado}
                    required
                    readOnly={this.state.resultadoEdit}
                  ></textarea>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="indicador">Indicador (es)</label>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      ref={this.indicadorRef}
                      defaultValue={indicador}
                      readOnly={this.state.indicadorEdit}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="meta">Meta</label>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm  text-dark"
                      id="meta"
                      placeholder="Meta del producto"
                      ref={this.metaRef}
                      defaultValue={meta}
                      readOnly={this.state.metaEdit}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group input-group-sm col-md-6">
                    <div>
                      <label htmlFor="productoProgramatica">
                        Producto Programática
                      </label>
                    </div>
                    {}
                    <Multiselect
                      readOnly={this.state.productoProgramaticaEdit}
                      placeholder="--Seleccione la programática--"
                      singleSelect= {true}
                      data={productoProgramaticas}
                      valueField="id"
                      defaultValue={programatica}
                      textField="programatica"
                      id="productoProgramaticas"
                      onChange={(e) => {
                        this.productoProgramaticaRef = [];
                        e.map((productoProgramatica) =>{
                          this.productoProgramaticaRef.push(
                            productoProgramatica
                          )
                        }
                        );
                      }}
                      minLength={3}
                      required
                    />
                  </div>
                </div>
                  <button type="submit" className="btn btn-primary">
                    Guardar Cambios
                  </button>
              </form>
            </div>
            <ReactTooltip globalEventOff="click" />
            <ReactTooltip />
          </Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    planesEstrategicos: state.planesEstrategicos,
    involucrados: state.involucrados.involucrados,
    areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
    productoProgramaticas: state.productoProgramaticas.programaticas,
    productoNopoa: state.productosNoPoa.productoNopoa,
    isLoading: state.productosNoPoa.isLoading,
    user: state.auth.signed,
    totalPonderacionProductosNopoa: state.productosNoPoa.totalPonderacionProductosNoPoa,
    ponderacionGeneral: state.productosNoPoa.totalPonderacionGeneralProductos,
    ponderacionArea: state.ponderacionAreas.ponderacionArea,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getEjesEstrategicos: () => dispatch(getEjesEstrategicos()),
  getProductoProgramatica: () => dispatch(getProductoProgramatica()),
  getEstrategias: () => dispatch(getEstrategias()),
  getObjetivosEstrategicos: () => dispatch(getObjetivosEstrategicos()),
  getInvolucrados: () => dispatch(getInvolucrados()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getAreasOrganizacionalesResponsables: () => dispatch(getAreasOrganizacionalesResponsables()),
  getProductoNopoa: (productoNoPoaId) => dispatch(getProductoNopoa(productoNoPoaId)),
  updateProductoNopoa: (producto) => dispatch(updateProductoNopoa(producto)),
  calcularPonderacionGeneral: (poaId) => dispatch(calcularPonderacionGeneral(poaId)),
  getPonderacionArea: (poaId, areaOrganizacionalId) => dispatch(getPonderacionArea(poaId, areaOrganizacionalId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarProductoNopoa, [
    "Administrador general",
    "Producto Fuera del POA",
    "Gestor del POA",
    "MAE",
  ])
);
