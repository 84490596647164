import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMedioVerificacion,
  updateMedioVerificacion
} from "./../../redux/actions/medios-verificacion-actions";
import Loading from "./../utils/Loading.jsx";

import { Authorization } from "./../../utils/Authorization";

import {
  Container
} from "reactstrap";

class EditarMedioVerificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      descripcion: "",
      estado: false
    };
  }
  componentDidMount() {
    const { medioVerificacionId } = this.props.match.params;
    this.props.getMedioVerificacion(medioVerificacionId);
  }
  componentWillReceiveProps(nextProps, nextState) {
    const { descripcion, estado } = nextProps.medioVerificacion;
    this.setState({ descripcion, estado, isLoading: false });
  }
  descripcionRef = React.createRef();
  estadoRef = React.createRef();

  actualizarMedioVerificacion = e => {
    e.preventDefault();
    
    let editarMedioVerificacion = {
      descripcion: this.descripcionRef.current.value,
      estado: this.estadoRef.current.value ? 1 : 0,
      medioVerificacionId: this.props.medioVerificacion.medioVerificacionId,
      actualizadoPor: this.props.user.id
    };
    this.props
      .updateMedioVerificacion(editarMedioVerificacion)
      .then(resultado => {
        this.props.history.push("/admin/mediosverificacion/index");
      });
  };

  render() {
    const { isLoading, descripcion, estado } = this.state;
    return (
      <>
        {!this.props.isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form
                  className="p-3"
                  onSubmit={this.actualizarMedioVerificacion}
                >
                  <div className="form-group">
                    <label htmlFor="descripción">Medio de Verificación</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Medio de Verificación"
                      ref={this.descripcionRef}
                      defaultValue={descripcion}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="estado"
                      ref={this.estadoRef}
                    >
                      <option value={estado} defaultValue={estado}>
                        {estado ? "Activo" : "Inactivo"}
                      </option>
                      {estado ? (
                        <option value={false}>Inactivo</option>
                      ) : (
                        <option value={true}>Activo</option>
                      )}
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Editar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    mediosVerificacion: state.mediosVerificacion.mediosVerificacion,
    medioVerificacion: state.mediosVerificacion.medioVerificacion,
    isLoading: state.mediosVerificacion.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  updateMedioVerificacion: medioVerificacion =>
    dispatch(updateMedioVerificacion(medioVerificacion)),
  getMedioVerificacion: medioVerificacionId =>
    dispatch(getMedioVerificacion(medioVerificacionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarMedioVerificacion, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE"
  ])
);
