import {
    REGISTRATION_REQUEST_SENT,
    REGISTRATION_REQUEST_SUCCEEDED,
    REGISTRATION_REQUEST_FAILED,
    VERIFY_TOKEN_REQUEST_SENT,
    VERIFY_TOKEN_REQUEST_SUCCEEDED,
    VERIFY_TOKEN_REQUEST_FAILED,
    SIGNIN_REQUEST_SENT,
    SIGNIN_REQUEST_SUCCEEDED,
    SIGNIN_REQUEST_FAILED,
    SIGNOUT_REQUEST_SENT,
    SIGNOUT_REQUEST_SUCCEEDED,
    SIGNOUT_REQUEST_FAILED,
    VERIFY_USER_SIGNED,
    USER_SIGNED_SUCCESS,
    USER_SIGNED_FAILED,
    SHOW_TOKEN
} from "../types";
import initialState from "../initial-state";

const { auth } = initialState;

const authRecuder = (state = auth, action) => {
    switch (action.type) {
        case REGISTRATION_REQUEST_SENT:
        case VERIFY_TOKEN_REQUEST_SENT:
        case SIGNIN_REQUEST_SENT:
        case SIGNOUT_REQUEST_SENT:
        case VERIFY_USER_SIGNED:
            return {
                ...state,
                isLoading: true
            };
        case REGISTRATION_REQUEST_SUCCEEDED:
        case VERIFY_TOKEN_REQUEST_SUCCEEDED:
        case SIGNIN_REQUEST_SUCCEEDED:
            const { attributes, token } = action.payload;
            //console.log("login succeed", token);
            return {
                ...state,
                attributes: attributes,
                token: token,
                isLoading: false,
                isSignedIn: true
            };
        case REGISTRATION_REQUEST_FAILED:
        case VERIFY_TOKEN_REQUEST_FAILED:
        case SIGNIN_REQUEST_FAILED:
        case USER_SIGNED_FAILED:
            return {
                ...state,
                isLoading: false,
                isSignedIn: false,
                signed: {}
            };
        case SIGNOUT_REQUEST_SUCCEEDED:
            const userAttributeKeys = Object.keys(state.attributes);
            const allNullUserAttributes = userAttributeKeys.reduce(
                (accumulatedNullUserAttributes, currentUserAttributeKey) => {
                    return {
                        ...accumulatedNullUserAttributes,
                        [currentUserAttributeKey]: null
                    };
                }, {}
            );
            localStorage.clear();
            return {
                // ...state,
                attributes: allNullUserAttributes,
                isLoading: false,
                isSignedIn: false
            };
        case SIGNOUT_REQUEST_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case USER_SIGNED_SUCCESS:
        action.payload.user.roles = action.payload.roles;

            return {
                ...state,
                isLoading: false,
                signed: action.payload.user
            };
        case SHOW_TOKEN:
            return {
                ...state,
                token: JSON.parse(localStorage.getItem("jwt"))
            };
        default:
            return state;
    }
};

export default authRecuder;