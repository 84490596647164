import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteMedioVerificacion } from "./../../redux/actions/medios-verificacion-actions";
import { Button } from "reactstrap";

class MedioVerificacion extends Component {
  eliminarMedioVerificacion = () => {
    
    let confirmed = window.confirm(
      "Está seguro de eliminar el medio de verificación"
    );
    if (confirmed) {
      this.props.deleteMedioVerificacion(this.props.medioVerificacion.medioVerificacionId);
      window.location.reload();
    }
  };

  render() {
    const {
      descripcion,
      estado,
      medioVerificacionId,
    } = this.props.medioVerificacion;

    return (
      <tr>
        <td> {descripcion}</td>
        <td>{estado ? "Activo" : "Inactivo"}</td>
        <td>
          {this.props.user.nivelAprobacion.nivelAprobacionId < 3 ? (
            <Button
              className="btn btn-success btn-sm"
              disabled={true}
              title="No tiene permisos para editar los medios de verificación"
            >
              Editar
            </Button>
          ) : (
            <Link
              // to={`/admin/medioverificacion/editar/${medioVerificacionId}`}
              to={{
                pathname: `/admin/medioverificacion/editar/${medioVerificacionId}`,
                state: "/admin/medioverificacion/editar/:medioVerificacionId",
              }}
              className="btn btn-success btn-sm"
            >
              Editar
            </Link>
          )}
          <button
            onClick={() => this.eliminarMedioVerificacion()}
            className="btn btn-danger btn-sm"
            disabled={this.props.user.nivelAprobacion.nivelAprobacionId < 3}
            title={
              this.props.user.nivelAprobacion.nivelAprobacionId < 3
                ? "No tiene permisos para eliminar los medios de verificación"
                : ""
            }
          >
            Eliminar
          </button>
        </td>
      </tr>
    );
  }
}

export default connect(null, { deleteMedioVerificacion })(MedioVerificacion);
