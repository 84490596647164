import React, { Component } from "react";
import Select from "react-select";
import Async from "react-select/async";
import Loading from "./Loading";
import NumberFormat from "react-number-format";
import { Multiselect } from "react-widgets";
import { meses } from "./../../utils/mesesByTrimestre"
import numbro from "numbro";
import { TextInputMask,moneyMask } from "react-masked-text";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";

export const ModalInsumosEditar = (props) => {
  let cuentaRef = React.createRef();
  let noCuentaRef = React.createRef();
  let insumoRef = React.createRef();
  let operacionRef = React.createRef();
  let unidadMedidaRef = React.createRef();
  let totalRef = React.createRef();
  let valorRef = React.createRef();
  let cantidadRef = React.createRef();
  let frecuenciaRef = React.createRef();
  let subclaseRef = React.createRef();
  let definicionRef = React.createRef();
  let denominacionRef = React.createRef();
  let referenciaInsumoRef = React.createRef();
  let insumoIdRef = React.createRef();
  let mesesCalendarSelectedRef = React.createRef();
  let number = 0;
  let mesesCalendar = [];
  const toggle = () => props.ActivarModalInsumos();
  
  const validarMesesLista = () => {
    if( props.state.mesesHabilesCalendar && props.state.mesesHabilesCalendar.length>0 ){
      props.state.mesesHabilesCalendar.forEach(element => {
        mesesCalendar = [...mesesCalendar, ...meses[element]]
      });
    }else{
      mesesCalendar=[]
    }

  }
  validarMesesLista();
  const handleCuenta = (e) => {
    e.preventDefault();

    props.consultarInsumosActividad(cuentaRef.current.value, null, null);
  };

  const handleInsumo = (newValue, e) => {
    //  e.preventDefault();
    let prueba = document.getElementsByName("insumoFiltro").value;

    const inputValue = newValue.replace(/\W/g, "");

    if (inputValue !== "") {
      props.consultarInsumosActividad(
        props.state.cuentaFiltro,
        inputValue,
        props.state.operacionFiltro
      );
    }
  };

  const handleOperacion = (newValue, e) => {
    //e.preventDefault();
    const inputValue = newValue;

    if (inputValue !== "") {
      props.consultarInsumosActividad(
        props.state.cuentaFiltro,
        props.state.insumoFiltro,
        inputValue
      );
    }
  };

  const calcularValorInsumo = () => {
    if (
      cantidadRef.current !== null &&
      frecuenciaRef.current !== null &&
      valorRef.current !== null &&
      cantidadRef.current.value !== "" &&
      frecuenciaRef.current.value !== "" &&
      valorRef.current !== ""
    ) {
       number =
        cantidadRef.current.value *
        frecuenciaRef.current.value *
        valorRef.current.value;
      totalRef.current.value = number;
    }else if (props.state.insumoEditar){
      number = props.state.insumoEditar.cantidad * props.state.insumoEditar.frecuencia * props.state.insumoEditar.valor;
      totalRef.current.value = number
    } 
    else {
      if (totalRef.current != null) {
        totalRef.current.value = "";
      }
    }
  };

  const actualizarPresupuesto = () => {
    
    if (validarDatos()) {
      let presupuesto = {
        descripcion: insumoRef.current.value,
        // operacion: operacionRef.current.value,
        unidadMedida: unidadMedidaRef.current.value,
        valor: valorRef.current.value,
        cuenta: noCuentaRef.current.value,
        cantidad: cantidadRef.current.value,
        frecuencia: frecuenciaRef.current.value,
        insumo: insumoRef.current.value,
        referenciaInsumo: referenciaInsumoRef.current.value,
        insumoId: parseInt(insumoIdRef.current.value),
        subclase: subclaseRef.current.value,
        presupuestoId: props.state.insumoEditar.presupuestoId,
        mesCompra:mesesCalendarSelectedRef.toString(),
        fechaCreacion: props.state.insumoEditar.fechaCreacion,
        creadoPor: props.state.insumoEditar.creadoPor
      };
      // console.log("presupuesto",presupuesto)
      props.editarInsumos(presupuesto);
    }
  };

  const validarDatos = () => {
    if (insumoRef.current === null || insumoRef.current.value === "") {
      swal("Insumos", "El insumo es un valor requerido", "error");

      return false;
    }

    if (cantidadRef.current === null || cantidadRef.current.value === "") {
      swal("Insumos", "La cantidad es un valor requerido", "error");

      return false;
    }

    if (frecuenciaRef.current === null || frecuenciaRef.current.value === "") {
      swal("Insumos", "La frecuencia es un valor requerido", "error");

      return false;
    }
    return true;
  };

  const { modalInsumosEditar, maestroInsumos, operaciones } = props;

  const converMesCompraArray = () => {
     mesesCalendarSelectedRef = [];
    if (props.state.insumoEditar.mesCompra) {
      let array = props.state.insumoEditar.mesCompra.split(',');
      // console.log(array);
      array.forEach(element => {
        mesesCalendarSelectedRef.push(parseInt(element));
        // console.log(parseInt(element))
      });

    }else{
      mesesCalendarSelectedRef =[];
    }
    // console.log("mesesCalendarSelectedRef", mesesCalendarSelectedRef)
  }

  const CalcularTotal = ()=>{
    return props.state.insumoEditar.cantidad * props.state.insumoEditar.frecuencia * props.state.insumoEditar.valor; 
  }


  converMesCompraArray();
  return (
    <Modal
      isOpen={modalInsumosEditar}
      toggle={toggle}
      size="lg"
      backdrop="static"
    >
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Actualizar Insumo de la actividad
      </ModalHeader>
      <ModalBody className="block-example border-bottom ">
        <form>
          <div className="form-row mt-1">
            <div className="col-12">
              <label>Insumo</label>
              <input
                type="text"
                ref={insumoRef}
                className="form-control text-dark"
                placeholder="Insumo"
                defaultValue={props.state.insumoEditar.insumo}
                readOnly
              />
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="cantidad">Cantidad</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="cantidad"
                  placeholder="Cantidad"
                  ref={cantidadRef}
                  onKeyUp={() => calcularValorInsumo()}
                  defaultValue={props.state.insumoEditar.cantidad}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="frecuencia">Frecuencia</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="frecuencia"
                  placeholder="Frecuencia"
                  ref={frecuenciaRef}
                  defaultValue={props.state.insumoEditar.frecuencia}
                  onKeyUp={() => calcularValorInsumo()}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="frecuencia">Valor</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="valor"
                  placeholder="Valor"
                  ref={valorRef}
                  defaultValue={props.state.insumoEditar.valor}
                  onKeyUp={() => calcularValorInsumo()}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="total">Total</label>{" "}
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="total"
                  placeholder="Total"
                  readOnly
                  required
                  ref={totalRef}
                  defaultValue={CalcularTotal()}
                />
                
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-5">
              <div className="form-group">

                <label htmlFor="unidadResponsable">
                  Mes Compra Insumo
                </label>

                {props.state.insumoEditar.totalCosto ? (<Multiselect

                  disabled={!props.state.insumoEditar.totalCosto}

                  placeholder="--Seleccione el Mes--"
                  data={mesesCalendar}
                  valueField="id"
                  id="mesInsumo"
                  textField="value"
                  defaultValue={mesesCalendarSelectedRef}
                  onChange={(e) => {
                    mesesCalendarSelectedRef = [];
                    e.map((mes) =>
                      mesesCalendarSelectedRef.push(
                        mes.id
                      )
                    );
                    // console.log(mesesCalendarSelectedRef);
                  }}
                // minLength={3}

                />) : (<Multiselect
                  readOnly
                  disabled={!props.state.insumoEditar.totalCosto}

                  placeholder="--Seleccione el Mes--"
                  data={mesesCalendar}
                  defaultValue={mesesCalendarSelectedRef}
                  valueField="id"
                  id="mesInsumo"
                  textField="value"

                // minLength={3}

                />)}



              </div>
            </div>
            <div className="col-7">
              <div className="form-group ">
                <label htmlFor="referenciaInsumo">Referencia Insumo</label>
                <textarea rows={1} defaultValue={props.state.insumoEditar.referenciaInsumo} readOnly className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo" ref={referenciaInsumoRef} ></textarea>
              </div>
            </div>
          </div>
          {/* <div className="form-row mt-1">

            <div className="col-12">
              <div className="form-group ">
                <label htmlFor="referenciaInsumo">Referencia Insumo</label>
                <textarea rows={1} defaultValue={props.state.insumoEditar.referenciaInsumo} readOnly className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo" ref={referenciaInsumoRef} ></textarea>
              </div>
            </div>

          </div> */}
          <div className="form-row mt-1">
            <div className="col-2">
              <div className="form-group ">
                <label htmlFor="noCuenta">Cuenta</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="noCuenta"
                  placeholder="Cuenta"
                  required
                  ref={noCuentaRef}
                  defaultValue={props.state.insumoEditar.cuenta}
                  readOnly
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group ">
                <label htmlFor="subclase">Subclase</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="subclase"
                  placeholder="Subclase"
                  ref={subclaseRef}
                  defaultValue={props.state.insumoEditar.subclase}

                  readOnly
                />
              </div>
            </div>
            <div className="col-2" hidden>
              <div className="form-group ">
                <label htmlFor="insumoId">insumoId</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="insumoId"
                  placeholder="Insumo Id"
                  ref={insumoIdRef}
                  defaultValue={props.state.insumoEditar.insumoId}
                  hidden
                  readOnly
                />
              </div>
            </div>

            <div className="col-8">
              <div className="form-group ">
                <label htmlFor="denominacion">Unidad de Medida</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="unidadMedida"
                  placeholder="Unidad de Medida"
                  required
                  ref={unidadMedidaRef}
                  defaultValue={props.state.insumoEditar.unidadMedida}
                  readOnly
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            {/* <div className="col-4" hidden>
              <div className="form-group ">
                <label htmlFor="valor">Valor</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="valor"
                  placeholder="Valor"
                  readOnly
                  ref={valorRef}
                  defaultValue={props.state.insumoEditar.valor}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="block-example border-bottom ">
        <button
          className="btn btn-info"
          onClick={(e) => {
            e.preventDefault();
            actualizarPresupuesto();
          }}
        >
          Guardar
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            props.ActivarModalInsumos();
          }}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};
