import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../../utils/Loading.jsx";
import ListadoActividadesAdministracionNoPoa from "../actividadesNoPoa/listadoActividadesAdministracion.jsx";
import { getProductoNopoa, getDocumentoDescargar, getProductosAdministracionNopoa } from "../../../redux/actions/productos-actions.js";
import { calcularPonderacionArea } from "../../../redux/actions/actividades-actions.js";
import { UncontrolledTooltip } from "reactstrap";
import { Authorization } from "../../../utils/Authorization.js";
import {
  Container,
} from "reactstrap";

class DetalleProductoNoPoaAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: {},
      isLoadingState: true
    };
  }

componentDidMount() {
    const { productoNoPoaId } = this.props.match.params;

    let productoNopoa = this.props.productosNoPoa.find(
      productoNopoa => productoNopoa.productoNoPoaId == parseInt(productoNoPoaId)
    ); 

    if (productoNopoa && productoNopoa.nivelAprobacionId) {
        this.props.productoNopoa.nivelAprobacionId = productoNopoa.nivelAprobacionId;
    } else {
        console.log('nivelAprobacionId esta undefined o productoNopoa no fue encontrado/cargado.');
    }
 
    this.consultarProducto(productoNoPoaId);
}

  validarOpcionEditar = () => {
    let resultado =
      this.props.user.nivelAprobacion.nivelAprobacionId ===
        this.props.productoNopoa.nivelAprobacionId.nivelAprobacionId &&
      this.props.poaActivo.nivelAprobacionId === 1;

    return resultado;
  };

  consultarProducto = async productoNoPoaId => {
    await this.props.getProductoNopoa(productoNoPoaId);
    // await this.props.getProductoNopoa(productoNoPoaId);
    await this.props.calcularPonderacionArea(this.props.productoNopoa.actividadesNopoa);
    this.setState({isLoadingState:false});
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { producto } = nextProps;
    this.setState({
      producto: producto,
      isLoading: false
    });
  }

  dispatchProductos = productoNoPoaId => async dispatch => {
    dispatch(getProductoNopoa(productoNoPoaId));
  };

  descargarDocumentoNoPoa = async (productoNoPoaId) => {
    await this.props.getDocumentoDescargar(productoNoPoaId);
  }

  render() {
    const { productoNopoa } = this.props;

    const {
      productoNoPoaId,
      descripcion,
      resultado,
      meta,
      indicador,
      actividadesNopoa,
      observacion,
      documento,
      documentoGestorProductoPoa
    } = productoNopoa;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          {this.state.isLoadingState? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >
             <div className="form-group">
              <label htmlFor="descripción">Producto</label>
              <input
                type="text"
                className="form-control form-control-sm text-dark"
                id="descripción"
                placeholder="Nombre del producto"
                value={descripcion}
                readOnly
              />
            </div>

            <div className="row">
              <div className="col-md-9">
                  <div className="form-group ">
                    <label htmlFor="descripción">Resultado</label>
                    <textarea
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="resultado"
                      placeholder="Resultado del producto"
                      value={resultado}
                      readOnly
                    />
                  </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="meta">Documento - Gestor No Poa</label>
                     { documentoGestorProductoPoa ?
                    <a 
                    className="p-2" 
                    title={ documentoGestorProductoPoa } 
                    onClick={async () => {
                      this.descargarDocumentoNoPoa(productoNoPoaId)
                    }}
                    >
                    <i className="fas fa-download text-primary" id="TooltipDescargarDocumentoGestorNoPoa">{ ' ' + documentoGestorProductoPoa.substring(0, 25) + ' ...' }</i>
                    <UncontrolledTooltip
                      placement="down"
                      target="TooltipDescargarDocumentoGestorNoPoa"
                    >
                      {documentoGestorProductoPoa}
                    </UncontrolledTooltip>
                  </a>
                    : <a className="p-2">
                    <i className="fas fa-download text-muted"></i>
                    </a>
                    }
              </div>
            </div>

                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="indicador">Indicador</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      value={indicador}
                      readOnly
                    />
                  </div>
                 
                  <div className="form-group col-md-4">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="meta"
                      placeholder="Meta"
                      value={meta}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="meta">Documento</label>
                     { documento ?
                    <a 
                    className="p-2" 
                    title={ documento } 
                    onClick={async () => {
                      this.descargarDocumentoNoPoa(productoNoPoaId)
                    }}
                    >
                    <i className="fas fa-download text-primary" id="TooltipDescargarDocumentoNoPoa">{ ' ' + documento.substring(0, 25) + ' ...' }</i>
                    <UncontrolledTooltip
                      placement="down"
                      target="TooltipDescargarDocumentoNoPoa"
                    >
                      {documento}
                    </UncontrolledTooltip>
                  </a>
                    : <a className="p-2">
                    <i className="fas fa-download text-muted"></i>
                    </a>
                    }
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="observacion">Observacion por devolucion</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="observacion"
                    placeholder="Descripción de la devolución"
                    defaultValue={observacion}
                    readOnly
                  />
                </div>
                {actividadesNopoa && (
                  <ListadoActividadesAdministracionNoPoa
                    validarOpcionEditar={this.validarOpcionEditar}
                    productoNoPoaId={productoNoPoaId}
                    actividadesprueba={actividadesNopoa}
                  ></ListadoActividadesAdministracionNoPoa>
                )}
              </form>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productosNoPoa: state.productosNoPoa.productosAdministracionNoPoa,
  productoNopoa: state.productosNoPoa.productoNopoa,
  isLoading: state.productos.isLoading,
  user: state.auth.signed,
  poaActivo: state.poas.poaActivo
});

const mapDispatchToProps = dispatch => ({
  getProductoNopoa: productoNoPoaId => dispatch(getProductoNopoa(productoNoPoaId)),
  getProductosAdministracionNopoa: (start, area) => dispatch(getProductosAdministracionNopoa(start, area)),
  calcularPonderacionArea: actividades => dispatch(calcularPonderacionArea(actividades)),
  getDocumentoDescargar: (productoNoPoaId) => dispatch(getDocumentoDescargar(productoNoPoaId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DetalleProductoNoPoaAdmin, [
    "Administrador general",
    "Gestor del POA",
    "MAE"
  ])
);
