import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteInvolucrados } from "../../redux/actions/involucrados-actions.js";
class Involucrado extends Component {
  state = {};
  
  eliminarInvolucrado = () => {
    let confirmed = window.confirm("Está seguro de eliminar el involucrado");
    if (confirmed) {
      this.props.deleteInvolucrados(this.props.involucrado);
    }
  };
  render() {
    
    const { involucradoId, descripcion, estado } = this.props.involucrado;

    return (
      <tr className="text-left">
        <td>{descripcion}</td>
        <td>{estado ? "Activo" : "Inactivo"}</td>
        <td>
          {this.props.user.nivelAprobacion.nivelAprobacionId < 3 ? (
            <button
              className="btn btn-success btn-sm"
              disabled={true}
              title="No tiene permisos para editar los involucrados"
            >
              Editar
            </button>
          ) : (
            <Link
              //to={`editar/${involucradoId}`}
              to={{
                pathname: `editar/${involucradoId}`,
                state: "/admin/involucrado/editar/:involucradoId",
              }}
              className="btn btn-success btn-sm"
            >
              Editar
            </Link>
          )}
          <button
            onClick={this.eliminarInvolucrado}
            className="btn btn-danger btn-sm"
            disabled={this.props.user.nivelAprobacion.nivelAprobacionId < 3}
            title={
              this.props.user.nivelAprobacion.nivelAprobacionId < 3
                ? "No tiene permisos para eliminar los involucrados"
                : ""
            }
          >
            Eliminar
          </button>
        </td>
      </tr>
    );
  }
}

export default connect(null, { deleteInvolucrados })(Involucrado);
