import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getProductos,
  updateProducto,
  calcularPonderacionArea,
  calcularPonderacionGeneral,
} from "../../redux/actions/productos-actions.js";
import {
  getPoaActivo,
  mostrarPoas,
  mostrarPoa,
} from "./../../redux/actions/poa-actions";
import { getReporteMonitoreo } from "./../../redux/actions/monitoreo-actividad-actions";
import Producto from "./Producto.jsx";
import Loading from "../utils/Loading.jsx";
import { Authorization } from "./../../utils/Authorization";
import { ModalLoading } from "./../utils/ModalLoading";

import { getAreasOrganizacionales } from "./../../redux/actions/areas-actions"; //me
import { Multiselect, DropdownList } from "react-widgets";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";
import swal from "sweetalert";
class ListaProductos extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoadingState: false,
      modal: false,
      areaidSeleccionada:this.props.user.areaOrganizacionalId
    };
  }

  areaOrganizacionalRef = {};
  async componentDidMount() {
    this.setState({ isLoadingState: true });

    if (
      Object.keys(this.props.poaActivo).length === 0 &&
      this.props.poaActivo !== undefined &&
      this.props.poaActivo !== null
    ) {
     await this.cargarPoa();
    } else {
      const { poaId } = this.props.poaActivo;
      this.cargarDatos(poaId);
    }
    this.props.getAreasOrganizacionales();
    this.props.mostrarPoas();
    this.setState({areaidSeleccionada:this.props.user.areaOrganizacional.areaOrganizacionalId})
    
  }

  cargarPoa = async () => {
    await this.props.getPoaActivo();
    const { poaId } = this.props.poaActivo;
  };

  cargarDatos = async (poaId,area=this.props.user.areaOrganizacional.areaOrganizacionalId) => {
    await this.props.mostrarPoa(poaId);
    await this.props.getProductos(poaId,area);
  
    let ListaProductos = this.props.productos;
    
    await this.props.calcularPonderacionArea(ListaProductos, this.props.user);
    await this.props.calcularPonderacionGeneral(poaId);
    this.setState({ isLoadingState: false });
    if(area>0){this.setState({areaidSeleccionada:area});}
  };

  aprobarProducto = (producto) => {
    if (producto.cantidadActividades > 0) {
      swal({
        title: "Está seguro de enviar a revisión este producto?",
        text: "Una vez enviado, no podrá ser editado",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then( async (willSend) => {
        if (willSend) {
            let productoEditar = {
              productoId: producto.productoId,
              descripcion: producto.descripcion,
              resultado: producto.resultado,
              indicador: producto.indicador,
              meta: producto.meta,
              ponderacionArea: producto.ponderacionArea,
              ejeEstrategico: producto.ejeEstrategico,
              objetivoEstrategico:
                producto.objetivoEstrategico,
              estrategia: producto.estrategia,
              // unidadResponsables: producto.unidadResponsables,
              // involucrados: producto.involucradosProductos.involucradoId,
              recursosExterno: producto.recursosExterno,
              poaId: this.props.poaActivo.poaId,
              ponderacionGeneral: producto.ponderacionGeneral,
              nivelAprobacionId: this.props.user.nivelAprobacion.padre,
              accionMitigacion:producto.accionMitigacion,
              riesgoAsociado:producto.riesgoAsociado,
              modoEditar: true,
              email: this.props.user.email
            };
           await this.props.updateProducto(productoEditar);
          this.props.history.goBack();
            
        }
      });
    } else {
      swal("Insumos","El producto no puede ser enviado debido a que no tiene actividades asignadas","error");
    }
  };

  devolverProducto = (producto) => {
    swal({
      title: "Está seguro de devolver el producto?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        let productoEditar = {
          productoId: producto.productoId,
          descripcion: producto.descripcion,
          resultado: producto.resultado,
          indicador: producto.indicador,
          meta: producto.meta,
          ponderacionArea: producto.ponderacionArea,
          ejeEstrategico: producto.ejeEstrategico.ejeEstrategicoId,
          objetivoEstrategico: producto.objetivoEstrategico.objetivoEstrategicoId,
          estrategia: producto.estrategia.estrategiaId,
  
          unidadResponsables: producto.unidadResponsables,
          involucrados: producto.involucradosProductos.involucradoId,
          recursosExterno: producto.recursosExterno,
          poaId: this.props.poaActivo.poaId,
  
          ponderacionGeneral: producto.ponderacionGeneral,
          nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
          accionMitigacion: producto.accionMitigacion,
          riesgoAsociado:producto.riesgoAsociado,
          modoEditar: true,
        };
        this.props.updateProducto(productoEditar);
        
      }
    });
  };

  validarOpcionEditar = (producto) => {
    let resultado = true;
    if (this.props.productos.length > 0) {
      resultado =
        this.props.user.nivelAprobacion.nivelAprobacionId ===
          producto.nivelAprobacionId &&
        this.props.poaActivo.nivelAprobacionId === 1;
    }
    return resultado;
  };

  reporteMonitoreo = async () => {
    let monitoreo = {
      areaOrganizacionalId: this.props.user.areaOrganizacional.areaOrganizacionalId,
      poaId: this.props.poaActivo.poaId,
      areaOrganizacional:this.props.user.areaOrganizacional.nombre,
      ano:this.props.poaActivo.ano,
      trimestre:0
    };
    this.setState({ modal: true, mensaje: "Elaborando Reporte de Monitoreo" });
    await this.props.getReporteMonitoreo(monitoreo);
    this.setState({ mensaje: "Reporte descargado" });

    setTimeout(() => {
      this.setState({ modal: false });
    }, 1500);
  };

  render() {
    const { productos = [], isLoading,
      areaOrganizacionales
    } = this.props ?? {};
    
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <div className="container bg-white border py-3">
            {isLoading || this.state.isLoadingState ? (
              <Loading></Loading>
            ) : (
              <div className="header-body">
                <div className="row">
                  <div className="form-group col-md-4 ">
                    <label>Año del Poa</label>
                    <select
                      className="form-control"
                      defaultValue={this.props.poaActivo.poaId}
                      id="anoSelected"
                      ref={this.anoSelectedRef}
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });                     
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,this.state.areaidSeleccionada);
                      }}
                    >
                      {this.props.poas.map((poa) => (
                        <option key={poa.poaId} value={poa.poaId}>
                          {poa.ano}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-5 " style={{display:"none"}} >
                  <label>Filtro Área</label>
                     <select
                      className="form-control"
                      defaultValue={this.state.areaidSeleccionada}
                      id="areaSelected"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isLoadingState: true });
                        this.setState({areaidSeleccionada:document.getElementById("areaSelected").value});
                        this.cargarDatos(
                          document.getElementById("anoSelected").value,document.getElementById("areaSelected").value);
                      }}
                    >
                      {this.props.areaOrganizacionales.map((area) => (
                        <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                          {area.nombre}
                        </option>
                      ))}
                    </select> 
                  </div>

                  <div className="col-md-8 mt-3">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.reporteMonitoreo();
                      }}
                      disabled={this.props?.poaActivo?.nivelAprobacionId === 1}
                      className="btn btn-lg btn-success float-right ml-3"
                    >
                      Reporte Monitoreo
                    </button>

                    {this.validarOpcionEditar(this.props.productos[0]) ||
                    this.props.poaActivo.nivelAprobacionId === 1 ? (
                      <Link
                        className="btn btn-lg btn-info float-right mb-3"
                        to="/admin/producto/nuevo"
                      >
                        Agregar nuevo Producto
                      </Link>
                    ) : (
                      <button
                        title="Producto Enviado"
                        className="btn btn-lg btn-info float-right mb-3 opacity"
                        onClick={(e) => e.preventDefault()}
                      >
                        Agregar nuevo Producto
                      </button>
                    )}
                  </div>
                </div>
                <Table
                  style={{ backgroundColor: "#fff", color: "black" }}
                  responsive
                  className="table-striped"
                >
                  <thead>
                    <tr className="text-left">
                      <th>Producto</th>
                      <th>Meta</th>
                      <th>Indicador</th>
                      <th>Ponderación</th>
                      <th>Actividades</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody className="text-left">
                    {this.props.productos?.map((producto) => (
                      <Producto
                        totalPonderacionProductos={
                          this.props.totalPonderacionProductos
                        }
                        totalPonderacionActividades={producto.totalPonderacionActividad}
                        validarOpcionEditar={this.validarOpcionEditar}
                        aprobarProducto={this.aprobarProducto}
                        devolverProducto={this.devolverProducto}
                        producto={producto}
                        user={this.props.user}
                        key={producto.productoId}
                      ></Producto>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Container>

        <ModalLoading
          modal={this.state.modal}
          mensaje={this.state.mensaje}
        ></ModalLoading>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  poas: state.poas.poas,
  productos: state.productos.productos,
  totalPonderacionProductos: state.productos.totalPonderacionProductos,
  isLoading: state.productos.isLoading,
  user: state.auth.signed,
  ponderacionGeneral: state.productos.totalPonderacionGeneralProductos,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getProductos: (poaId,area) => dispatch(getProductos(poaId,area)),
  getPoaActivo: () => dispatch(getPoaActivo()),
  mostrarPoas: () => dispatch(mostrarPoas()),
  mostrarPoa: (poaId) => dispatch(mostrarPoa(poaId)),
  updateProducto: (producto) => dispatch(updateProducto(producto)),
  calcularPonderacionArea: (productos, user) =>
    dispatch(calcularPonderacionArea(productos, user)),
  calcularPonderacionGeneral: (poaId) =>
    dispatch(calcularPonderacionGeneral(poaId)),
  getReporteMonitoreo: (monitoreo) => dispatch(getReporteMonitoreo(monitoreo)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaProductos, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE",
  ])
);
