import React from "react";
import Loading from "./../utils/Loading";
import { saveAs } from "file-saver";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { api_download, api_downloadv2 } from "./../../config";

export const ModalArchivos = (props) => {
  
  const toggle = () => {
    props.activarModalArchivos(props.monitoreoMediosVerificacion, false, props.justificacionDeNoCumplimiento);
  }

  const truncateString = (str,num = 45) => {
    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  const { modalArchivos, monitoreoMediosVerificacion, justificacionDeNoCumplimiento } = props;

  return (
    <Modal isOpen={modalArchivos} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Archivos Monitoreo
      </ModalHeader>

      <ModalBody>
       
      {props.isLoadingModalArchivo?(<div style={{height:10}}><Loading></Loading></div>):( 
        <Table
          style={{ backgroundColor: "#fff", color: "black" }}
          responsive
          className="table-striped"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Medio verificación</th>
              <th>Archivo</th>
              <th>Descargar</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {monitoreoMediosVerificacion && monitoreoMediosVerificacion.map((medio, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td title={medio.descripcion}>{truncateString(medio.descripcion,35)}</td>
                <td title={medio.nombreImagen}>{truncateString(medio.nombreImagen).toLowerCase()} </td>
                <td>
                  <i
                    className=" btn fas fa-cloud-download-alt"
                    onClick={() => {
                      let url = `${api_downloadv2}${medio.documento}`;
                      saveAs(url, medio.nombreImagen);
                    }}
                  ></i>
                </td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    disabled={props.statusEliminarArchivo}
                    onClick={(e) => {
                      e.preventDefault();
                      swal({
                        title: "Esta seguro de querer eliminar este medio de verificación?",
                        text: "Una vez eliminado, no podrá ser recuperado!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      })
                      .then((confirm) => {
                        if (confirm) {
                          props.deletMonitoreoMedioVerificacion(
                          props.actividadId,
                          medio.monitoreoMedioVerificacionId
                          );
                          props.activarModalArchivos(
                            props.monitoreoMediosVerificacion  
                          );
                          window.location.reload();
                        }
                      });
                    }}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        )}

        {props.isLoadingModalArchivo?(<div style={{height:10}}><Loading></Loading></div>):( 
              <Table
                style={{ backgroundColor: "#fff", color: "black" }}
                responsive
                className="table-striped"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Evidencia de Justificación de No Cumplimiento</th>
                    <th>Descargar</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {justificacionDeNoCumplimiento && justificacionDeNoCumplimiento.map((evidencia, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td title={evidencia.nombreImagen}>{truncateString(evidencia.nombreImagen).toLowerCase()} </td>
                      <td>
                        <i
                          className=" btn fas fa-cloud-download-alt"
                          onClick={async() => {
                            await props.getReporteEvidenciaNoCumplimiento(evidencia.documento.substring(0, 4), evidencia.documento.substring(4, 8), evidencia.documento, evidencia.nombreImagen)
                          }}
                        ></i>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger btn-sm"
                          disabled={props.statusEliminarArchivo}
                          onClick={(e) => {
                            e.preventDefault();
                            swal({
                              title: "Esta seguro de querer eliminar esta evidencia de no cumplimiento?",
                              text: "Una vez eliminado, no podrá ser recuperado!",
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            })
                            .then((confirm) => {
                              if (confirm) {
                                props.deletMonitoreoEvidencia(
                                props.actividadId,
                                evidencia.id
                                );
                                props.activarModalArchivos(
                                  props.monitoreoMediosVerificacion  
                                );
                                window.location.reload();
                              }
                            });
                          }}
                        >
                          Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              )}

      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            props.activarModalArchivos(monitoreoMediosVerificacion);
          }}
        >
          Salir
        </button>
      </ModalFooter>
    </Modal>
  );
};
