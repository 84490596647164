import React, { Component } from "react";
import { connect } from "react-redux";
import { getActividades } from "./../../redux/actions/actividades-actions";
import Actividad from "./../actividades/Actividad.jsx";
import { Link } from "react-router-dom";
import { Authorization } from "./../../utils/Authorization";
import {
  Table,
} from "reactstrap";

class ListaActividades extends Component {

  componentDidMount() {
    // this.props.getActividades(this.props.productoId);
  }
  validarNivelAprobacionPoa = () => {
    if (this.props.poaActivo.nivelAprobacionId === 7) {
      return true;
    } else {
      return false;
    }
  };
  
  render() {
    const { isLoading, actividadesprueba, actividades } = this.props;

    

    return (
      <React.Fragment>
        <h3 className="mt-3" >Listado de Actividades</h3>

        {this.props.validarOpcionEditar() ? (
          <Link
            className="btn  btn-info float-right mb-3"
            to="/admin/actividad/nueva"
          >
            Agregar Actividades
          </Link>
        ) : (
          <button
            title="Producto Enviado"
            className="btn  btn-info float-right mb-3 opacity"
            onClick={e => e.preventDefault()}
          >
            Agregar Actividades
          </button>
        )}
        {actividadesprueba.length > 0 ? (
          <Table
            style={{ backgroundColor: "#fff", color: "black" }}
            responsive
            className="table-striped"
          >
            <thead>
              <tr className="">
                <th>Actividad</th>
                <th>ponderación</th>
                <th>Medio Verificacion</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {actividadesprueba.map(actividad => (
                <Actividad
                  productoId={this.props.productoId}
                  validarNivelAprobacionPoa={this.validarNivelAprobacionPoa}
                  validarOpcionEditar={this.props.validarOpcionEditar}
                  key={actividad.actividadId}
                  actividad={actividad}
                ></Actividad>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="row d-flex justify-content-center mb-3">
            <h5>No tiene actividades agregadas</h5>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  poaActivo: state.poas.poaActivo,
  actividades: state.actividades.actividades,
  isLoading: state.actividades.isLoading,
  user: state.auth.signed
});

const mapDispatchToProps = dispatch => ({
  // getActividades: productoId => dispatch(getActividades(productoId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaActividades, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE"
  ])
);
