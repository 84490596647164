import axios from "axios";
import { client, api_base } from "../config";
// import prueba from './../utils/store'
import { store } from "./store";


let infoUsuario = JSON.parse(localStorage.getItem("jwt"));

const prueba = () => {
  //  console.log("devuelve el store");
  store.dispatch({ type: "SHOW_TOKEN" });
  store.subscribe(() => store.getState().auth);
  // console.log(store.getState().auth);
  return store.getState().auth;
};

export const instance = axios.create({
  baseURL: api_base,
  timeout: 30000,
  data: {
    client_id: client.id,
    client_secret: client.secret,
  },
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});


export const getDocument = axios.create({
  baseURL: api_base,
  timeout: 30000,
  data: {
    client_id: client.id,
    client_secret: client.secret,
  },
  headers: {
    "Content-Type": "application/octet-stream",
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${
      prueba().token ? prueba().token : ""
    }`,
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export const getData = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${
      prueba().token ? prueba().token : ""
    }`,
  },
  timeout: 300000,
  // `transformResponse` allows changes to the response data to be made before
  // it is passed to then/catch
  transformResponse: [
    function (data) {
      let resp;
      try {
        resp = JSON.parse(data);
      } catch (error) {    
        throw Error(
          `[requestClient] Error parsing response JSON data - ${JSON.stringify(
            error
          )}`
        );
      }

      if (resp.status !== "error") {
        return resp;
      } else {
        throw `${resp.error}`;
      }
    },
  ],
});

export const getInformes = axios.create({
  headers: {
     "Cache-Control": "no-cache",
    Authorization: `Bearer ${
      prueba().token ? prueba().token.access_token : ""
    }`,
  },
  
  timeout: 300000,
  // `transformResponse` allows changes to the response data to be made before
  // it is passed to then/catch
  transformResponse: [
    function (data) {
      let resp;
      try {
        resp = data;
      } catch (error) {
        throw Error(
          `[requestClient] Error parsing response JSON data - ${JSON.stringify(
            error
          )}`
        );
      }

      if (resp.status !== "error") {
        return resp;
      } else {
        throw `${resp.error}`;
      }
    },
  ],
});

