import {
  CREAR_PRODUCTOS,
  MOSTRAR_PRODUCTOS,
  MOSTRAR_PRODUCTO,
  EDITAR_PRODUCTO,
  ELIMINAR_PRODUCTO,
  LOADING_PRODUCTO,
  PRODUCTO_SUCCEEDED,
  PRODUCTO_FAILED,
  MOSTRAR_PRODUCTOS_ADMINISTRACION,
  TOTAL_PONDERACION_AREA_PRODUCTOS,
  TOTAL_PONDERACION_GENERAL_PRODUCTOS,
  TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES,
  MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION,
  CLEAN_PRODUCTOS_ADMINISTRACION,
  MOSTRAR_PRODUCTOS_INSUMOS,

  // NO POA
  CREAR_PRODUCTOS_NO_POA,
  MOSTRAR_PRODUCTOS_NO_POA,
  MOSTRAR_PRODUCTO_NO_POA,
  EDITAR_PRODUCTO_NO_POA,
  ELIMINAR_PRODUCTO_NO_POA,
  LOADING_PRODUCTO_NO_POA,
  PRODUCTO_NO_POA_SUCCEEDED,
  PRODUCTO_NO_POA_FAILED,
  MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION,
  TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA,
  TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA,
  TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES,
  MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION,
  CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION,
  MOSTRAR_PRODUCTOS_NO_POA_INSUMOS,

} from "./../types";
import swal from "sweetalert";
import { api_base, api_basev2 } from "./../../config";
import { getData, getDocument} from "./../../utils/api-client";
import { VolverAlLogin } from "../../utils/utils";

export const getProductosActividadInsumos = (poaId, area) => async (dispatch) => {
  let url = `${api_base}api/v1/productosInsumos/poaId/${poaId}/${area}/productos`;

  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PRODUCTOS_INSUMOS, payload: resultado.data.data });
      dispatch({ type: PRODUCTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        //   window.location.reload();
      }
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(JSON.stringify(error.response.data.error));
    });
};
export const getProductos = (poaId, area) => async (dispatch) => {
  let url = `${api_base}api/v1/poaId/${poaId}/productos`;
  if (area > 0) {
    url = `${api_base}api/v1/poaId/${poaId}/${area}/productos`;
  } //me

  let urlv2 = api_basev2 + `Productos/ObtenerProductoPorId?id=${poaId}&areaOrg=${area}`;

  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .get(urlv2)
    .then((resultado) => {
      
      dispatch({ type: MOSTRAR_PRODUCTOS, payload: resultado.data.data });
      dispatch({ type: PRODUCTO_SUCCEEDED });

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        //   window.location.reload();
      }
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(JSON.stringify(error.response.data.error));
    });
};

export const getProducto = (productoId) => async (dispatch) => {
  let url = api_base + `api/v1/producto/${productoId}`;
  let urlv2 = api_basev2 + `Productos/DetalleProducto?productoId=${productoId}`;

  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PRODUCTO, payload: resultado.data.data });
      dispatch({ type: PRODUCTO_SUCCEEDED });
    })
    .catch((error) => {
      
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
        dispatch({ type: PRODUCTO_FAILED });
        //console.log(JSON.stringify(error.response.data.error));
      } else {
        // window.location.reload();
      }
    });
};

export const createProducto = (producto) => async (dispatch) => {
  let url = api_base + `api/v1/productos/crear`;
  let urlv2 = api_basev2 + `Productos/SaveProducto`;

  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .post(urlv2, JSON.stringify(producto))
    .then((resultado) => {
      dispatch({ type: CREAR_PRODUCTOS, payload: resultado.data });
      dispatch({ type: PRODUCTO_SUCCEEDED });
      swal(
        "Producto",
        "La operación ha tenido éxito.",
        "success"
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        window.location.reload();
        
      }
      dispatch({ type: PRODUCTO_FAILED });
      console.log(JSON.stringify(error.response.data.error));
    });
};
export const updateProducto = (producto) => async (dispatch) => {
  let url = api_base + `api/v1/productos/editar`;
  let urlv2 = api_basev2 + `Productos/UpdateProducto`
  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .post(urlv2, producto)
    .then((resultado) => {
      swal(
        "Producto",
        "Se ha enviado el producto.",
        "success"
      );
      dispatch({ type: EDITAR_PRODUCTO, payload: resultado.data.data });
      dispatch({ type: PRODUCTO_SUCCEEDED });
      
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        //  window.location.reload();
      }
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(JSON.stringify(error.response.data.error));
    });
};

export const deleteProducto = (producto, poaId) => async (dispatch) => {
  let url = api_base + `api/v1/poaId/${poaId}/productos/eliminar`;
  let urlv2 = api_basev2 + `Productos/DeleteProducto`;
  dispatch({ type: LOADING_PRODUCTO });
  await getData
    .post(urlv2, JSON.stringify(producto))
    .then((resultado) => {
      dispatch({ type: ELIMINAR_PRODUCTO, payload: producto.productoId });
      dispatch({ type: PRODUCTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        //  window.location.reload();
      }
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(JSON.stringify(error.response.data.error));
    });
};

export const getProductosAdministracion = (poaId, start, area = 0) => async (
  dispatch
) => {
  dispatch({ type: LOADING_PRODUCTO });

  let url = `${api_base}api/v1/poaId/${poaId}/productos/administracion/${start}`;
  if (area > 0) {
    url = `${api_base}api/v1/poaId/${poaId}/productos/administracion/${start}/${area}`;
  } //me

  await getData
    .get(url)
    .then((resultado) => {
      // console.log("Resultados", resultado.data);
      dispatch({
        type: MOSTRAR_PRODUCTOS_ADMINISTRACION,
        payload: resultado.data,
      });
      dispatch({ type: PRODUCTO_SUCCEEDED });
      //console.log(resultado.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
          
        }
      } else {
        // window.location.reload();
      }
      dispatch({
        type: MOSTRAR_PRODUCTOS_ADMINISTRACION,
        payload: [],
      });
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(error);
    });
};
export const getTotalProductosAdministracion = (poaId, areaid = 0) => async (
  dispatch
) => {
  dispatch({ type: LOADING_PRODUCTO });

  let url = `${api_base}api/v1/poaId/${poaId}/total/productos/administracion`;

  if (areaid > 0) {
    url = `${api_base}api/v1/poaId/${poaId}/total/productos/administracion/${areaid}`;
  }

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION,
        payload: resultado.data,
      });
      dispatch({ type: PRODUCTO_SUCCEEDED });
      // console.log(resultado.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        // window.location.reload();
      }
      dispatch({
        type: MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION,
        payload: 0,
      });
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(error);
    });
};

export const calcularPonderacionArea = (listaproductos, user) => async (
  dispatch
) => {
  // console.log(listaproductos);
  // console.log(user);
  let totalPonderacion = 0;

  for (let i = 0; i < listaproductos.length; i++) {
    if (
      listaproductos[i]?.areaOrganizacional?.areaOrganizacionalId ===
      user?.areaOrganizacional?.areaOrganizacionalId
    )
      totalPonderacion += parseFloat(listaproductos[i].ponderacionArea);
  }

  listaproductos?.forEach((producto) => {
    let totalPonderacionActividades = 0;
    producto?.actividades?.forEach((actividad) => {
      totalPonderacionActividades += parseFloat(actividad.ponderacionActividad);
    });

    let obj = {
      productoId: producto.productoId,
      totalPonderacionActividades,
    };
    dispatch({ type: TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES, payload: obj });
  });

  dispatch({
    type: TOTAL_PONDERACION_AREA_PRODUCTOS,
    payload: totalPonderacion,
  });
};

export const calcularPonderacionGeneral = (poaId) => async (dispatch) => {
  dispatch({ type: LOADING_PRODUCTO });
   let url = `${api_base}api/v1/ponderaciongeneral/poaId/${poaId}/`;
  let urlv2 = api_basev2 + `Productos/ObtenerPonderacionGeneral?poaId=${poaId}`

  getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({
        type: TOTAL_PONDERACION_GENERAL_PRODUCTOS,
        payload: resultado.data.data,
      });
      dispatch({ type: PRODUCTO_SUCCEEDED });
      //console.log(resultado.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      } else {
        // window.location.reload();
      }
      dispatch({
        type: TOTAL_PONDERACION_GENERAL_PRODUCTOS,
        payload: 0,
      });
      dispatch({ type: PRODUCTO_FAILED });
      //console.log(error);
    });
};

export const cleanProductosAdministracion = () => async (dispatch) => {
  dispatch({ type: CLEAN_PRODUCTOS_ADMINISTRACION });
};

// NO POA
export const getProductosActividadInsumosNopoa = (area) => async (dispatch) => {
  let url = `${api_base}api/v1/productosNoPoaInsumos/${area}/productos`;

  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PRODUCTOS_NO_POA_INSUMOS, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};
// NO POA Aprobados
export const getProductosActividadInsumosNopoaAprobados = (area) => async (dispatch) => {
  let url = `${api_base}api/v1/productosNoPoaInsumosAprobados/${area}/productos`;

  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PRODUCTOS_NO_POA_INSUMOS, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const getProductosNopoa = () => async (dispatch) => {
  let url = `${api_base}api/v1/productosNoPOA`;

  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .get(url)
    .then((resultado) => {
      // console.log("xxx",resultado.data);
      dispatch({ type: MOSTRAR_PRODUCTOS_NO_POA, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const getProductoNopoa = (productoId) => async (dispatch) => {
  let url = api_base + `api/v1/productosNoPOA/${productoId}`;
  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_PRODUCTO_NO_POA, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
        dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const createProductoNopoa = (producto) => async (dispatch) => {
  let url = api_base + `api/v1/productosNoPOA/crear`;

  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .post(url, producto, {
      headers: {
        "Content-Type": "multipart/form-data"
    }})
    .then((resultado) => {
      dispatch({ type: CREAR_PRODUCTOS_NO_POA, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const updateProductoNopoa = (producto) => async (dispatch) => {
  // console.log("update producto",producto)
  let url = api_base + `api/v1/productosNoPOA/actualizar`;
  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .post(url, JSON.stringify(producto))
    .then((resultado) => {
      // console.log("resultado data update", resultado.data);
      dispatch({ type: EDITAR_PRODUCTO_NO_POA, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const updateProductoAprobarDevolverNopoa = (producto) => async (dispatch) => {
  let url = api_base + `api/v1/productosNoPOA/aprobarDevolver`;

  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  
  await getData
    .post(url, producto, {
      headers: {
        "Content-Type": "multipart/form-data"
    }})
    .then((resultado) => {
      dispatch({ type: EDITAR_PRODUCTO_NO_POA, payload: resultado.data });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const deleteProductoNopoa = (producto) => async (dispatch) => {
  let url = api_base + `api/v1/productosNoPOA/eliminar`;
  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  await getData
    .post(url, JSON.stringify(producto))
    .then((resultado) => {
      dispatch({ type: ELIMINAR_PRODUCTO_NO_POA, payload: producto.productoId });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const getDocumentoDescargar = (productoNoPoaId) => async (dispatch) => {
  let url =
    api_base +
    `api/v1/productoNoPoa/descargar/${productoNoPoaId}`;
    dispatch({type:LOADING_PRODUCTO_NO_POA});
  await getDocument
    .get(url, {
      headers: {
        "Content-Type": "application/octet-stream",
      }, responseType: 'blob'
    },)
    .then((resultado) => {
      var a = document.createElement("a");
      let blob = new Blob([resultado.data], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `documento-producto-nopoa-${productoNoPoaId}-descargar.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: PRODUCTO_NO_POA_FAILED });
      }
    });
};

export const getProductosAdministracionNopoa = (start, area = 0) => async (dispatch) => {
  dispatch({ type: LOADING_PRODUCTO_NO_POA });

  let url = `${api_base}api/v1/productosNoPoa/administracion/${start}`;
  if (area > 0) {
    url = `${api_base}api/v1/productosNoPoa/administracion/${start}/${area}`;
  }

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION,
        payload: resultado.data,
      });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({
        type: MOSTRAR_PRODUCTOS_NO_POA_ADMINISTRACION,
        payload: [],
      });
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const getTotalProductosAdministracionNopoa = (areaid = 0) => async (dispatch) => {
  dispatch({ type: LOADING_PRODUCTO_NO_POA });

  let url = `${api_base}api/v1/total/productosNoPoa/administracion`;

  if (areaid > 0) {
    url = `${api_base}api/v1/total/productosNoPoa/administracion/${areaid}`;
  }

  await getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION,
        payload: resultado.data,
      });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({
        type: MOSTRAR_TOTAL_PRODUCTOS_NO_POA_ADMINISTRACION,
        payload: 0,
      });
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
    });
};

export const cleanProductosAdministracionNopoa = () => async (dispatch) => {
  dispatch({ type: CLEAN_PRODUCTOS_NO_POA_ADMINISTRACION });
};

export const calcularPonderacionAreaNopoa = (listaproductos, user) => async (dispatch) => {
  let totalPonderacion = 0;

  for (let i = 0; i < listaproductos.length; i++) {
    if (
      listaproductos[i].areaOrganizacionalId.areaOrganizacionalId ===
      user.areaOrganizacional.areaOrganizacionalId
    )
      totalPonderacion += parseFloat(listaproductos[i].ponderacionArea);
  }

  listaproductos?.forEach((producto) => {
    let totalPonderacionActividades = 0;
    producto.actividades.forEach((actividad) => {
      totalPonderacionActividades += parseFloat(actividad.ponderacionActividad);
    });

    let obj = {
      productoId: producto.productoId,
      totalPonderacionActividades,
    };
    dispatch({ type: TOTAL_PONDERACION_PRODUCTO_NO_POA_ACTIVIDADES, payload: obj });
  });

  dispatch({
    type: TOTAL_PONDERACION_AREA_PRODUCTOS_NO_POA,
    payload: totalPonderacion,
  });
};

export const calcularPonderacionGeneralNopoa = (poaId) => async (dispatch) => {
  dispatch({ type: LOADING_PRODUCTO_NO_POA });
  let url = `${api_base}api/v1/ponderaciongeneral/poaId/${poaId}/`;
  getData
    .get(url)
    .then((resultado) => {
      dispatch({
        type: TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA,
        payload: resultado.data,
      });
      dispatch({ type: PRODUCTO_NO_POA_SUCCEEDED });
      //console.log(resultado.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          // alert("La sesión del usuario esta vencida favor iniciar nuevamente ");
          //window.location.replace("/auth/login");
        }
      } else {
        // window.location.reload();
      }
      dispatch({
        type: TOTAL_PONDERACION_GENERAL_PRODUCTOS_NO_POA,
        payload: 0,
      });
      dispatch({ type: PRODUCTO_NO_POA_FAILED });
      //console.log(error);
    });
};
