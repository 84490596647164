import {
    UNIDADES_MEDIDA_SUCCEEDED,
    LOADING_UNIDADES_MEDIDA,
    MOSTRAR_UNIDADES_MEDIDA
} from "./../types";

import { getData } from "./../../utils/api-client";
import { api_base, api_basev2 } from "./../../config";

export const getUnidadesMedida = modulo => async dispatch => {
    const url = api_base + `api/v1/unidadesMedida/${modulo}`;
    const urlv2 = api_basev2 + `UnidadesMedida/GetUnidadesByModulo?modulo=${modulo}`
    dispatch({ type: LOADING_UNIDADES_MEDIDA });
    await getData
    .get(urlv2)
    .then(resultado => {
        dispatch({ type: MOSTRAR_UNIDADES_MEDIDA, payload: resultado.data.data });
        dispatch({ type: UNIDADES_MEDIDA_SUCCEEDED });
    });
};