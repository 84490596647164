import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  //Modal,
  //  Row,
  Spinner,
  Col,
} from "reactstrap";
import swal from "sweetalert";

import ModalLoading from "./../pages/utils/ModalLoading.jsx";

import { connect } from "react-redux";
import {
  signInUser,
  testAlgo,
  searchUser,
  signInRequestFailed,
} from "../redux/actions/auth-actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      jwt: null,
      isLoading: false,
      isLoggingIn: false,
    };
    
  }

  componentDidMount() {
    this.props.signInRequestFailed();
    const params = new URLSearchParams();
        //Validar si la sesion del usuario sigue activa en caso de que quiera acceder al login de nuevo.
        let jwt = localStorage.getItem("jwt");
        let email = JSON.parse(localStorage.getItem("usuario"));
        if(jwt != null && email != null){
        this.props.searchUser(email, jwt)
        };

    // this.props.signInRequestFailed();
    // params.append("event_id", "aaaaa");

    /*axios.post('http://localhost/test/index.php', params,
    ).then((data) => {
      //console.log("XXXXX: ", data);
    });*/

    /*    let jwt = localStorage.getItem("jwt");

    if ( jwt != null ) {
      this.setState({jwt: jwt});

      //TODO: Validar si el token es válido

  }*/
  }

  onTest() {
    this.props.testAlgo();
  }

  onLogin() {
    this.login();
  }

  login = async () => {
    let email = "";
    if (this.state.email.indexOf("@") !== -1) {
      email = this.state.email;
    } else {
      email = this.state.email.toLocaleLowerCase() + "@mem.gob.do";
    }

    await this.props
      .signInUser({
        email: email,
        password: this.state.password
        // grant_type: "password",
      })
      .then((resultado) => {
        
        if (this.props.auth.token) {
          this.props.searchUser(email, this.props.auth.token.access_token);
        } else {
          swal(
            "Inicio de Sesión",
            "Usuario y/o contraseña incorrecta favor verificar",
            "error"
          );
        }
      })
      .catch((error) => {
        // console.log(error);
        this.props.signInRequestFailed();
      });
  };

  updateInputValue(evt) {
    var stateObject = function () {
      let returnObj = {};
      returnObj[this.target.name] = this.target.value;
      return returnObj;
    }.bind(evt)();
    this.setState(stateObject);
  }
  handleKeyPress = (event) => {
    if (event.charCode === 13) {
      if (this.state.email !== "" && this.state.password !== "") {
        document.getElementById("loginButton").click();
      }
    }
  };

  render() {
    const { isLoading } = this.props;
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-3">
              <div className="btn-wrapper text-center">
                <img
                  style={{ width: 270 }}
                  alt="..."
                  src={require("../assets/img/logonuevo-left-portal.PNG")}
                />
              </div>
              <div className="text-muted text-center mt-3">
                <small>Sistema de Formulación y Monitoreo del POA</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-2">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Correo electrónico"
                      type="email"
                      name="email"
                      value={this.state.email}
                      onKeyPress={(e) => {
                        this.handleKeyPress(e);
                      }}
                      onChange={(evt) => this.updateInputValue(evt)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Contraseña"
                      type="password"
                      name="password"
                      value={this.state.password}
                      autoComplete="off"
                      onKeyPress={(e) => this.handleKeyPress(e)}
                      onChange={(evt) => this.updateInputValue(evt)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox"></div>
                <div className="text-right">
                  {isLoading ? (
                    <button
                      className="btn  btn-primary my-4"
                      type="button"
                      disabled
                    >
                      <div className="input-group">
                        <div className="spinner mr-2"></div>
                        Cargando...
                      </div>
                    </button>
                  ) : (
                    <Button
                      className="my-4"
                      id="loginButton"
                      color="primary"
                      type="button"
                      onClick={this.onLogin.bind(this)}
                    >
                      Iniciar sesión
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isLoading: state.auth.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signInUser: (data) => dispatch(signInUser(data)),
  testAlgo: () => dispatch(testAlgo()),
  searchUser: (email, token) => dispatch(searchUser(email, token)),
  signInRequestFailed: () => dispatch(signInRequestFailed()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(Login);
