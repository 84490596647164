import React from "react";
import { connect } from "react-redux";

export const Authorization = (WrappedComponent, allowedRoles, userRoles) => {
    return class whitAuthorization extends React.Component {
        constructor(props) {
            super(props);
            
            this.state = {
                user: {
                    name: "miguel eve",
                    role: userRoles
                }
            };
        }

        validarRoles = allowedRoles => {
            if (!this.props.user.roles) {
                alert("Se debe iniciar nuevamente la sesion");

               window.location.href = "/auth/login";
            }
            const { roles } = this.props.user;
            let resultado = false;
            for (let i = 0; i < roles.length; i++) {
                for (let index = 0; index < allowedRoles.length; index++) {
                    if (allowedRoles[index] === roles[i]) {
                        resultado = true;
                        
                        break;
                    } else {
                        resultado = false;
                    }
                }
                if (resultado) {
                    break;
                }
            }
            return resultado;
        };
        render() {
            if (this.validarRoles(allowedRoles)) {
                return <WrappedComponent {...this.props }
                />;
            } else {
                return ( <
                    div className = "pb-6 pt-5 pt-md-7 ml-20" >
                    <
                    div style = {
                        { marginLeft: "10px" }
                    } >
                    <
                    h1 > ¡Acceso denegado! < /h1> < /
                    div > <
                    div style = {
                        { marginLeft: "10px" }
                    } >
                    <
                    p >
                    No tiene permisos para ingresar a esta pagina, en caso de ser necesario favor comunicarse al <
                    strong >
                    Departamento de Formulación, Monitoreo y Evaluación de Planes, Programas y Proyectos <
                    /strong>
                    . <
                    /p> < /
                    div > <
                    /div>
                );
            }
        }
    };
};