import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../utils/Loading.jsx";
import ListadoActividades from "./../actividades/index.jsx";
import { getProducto } from "./../../redux/actions/productos-actions";
import { calcularPonderacionArea } from "./../../redux/actions/actividades-actions";

import { Authorization } from "./../../utils/Authorization";
import {
  Container
} from "reactstrap";

import {} from "./../../redux/types";

class DetalleProducto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      producto: {},
      isLoadingState: true
    };
  }
  componentDidMount() {
    const { productoId } = this.props.match.params;

    let producto = this.props.productos.find(
      producto => producto.productoId == parseInt(productoId)
    ); 

    this.consultarProducto(productoId);
  }

  validarOpcionEditar = () => {
    let resultado =
      this.props.user.nivelAprobacion.nivelAprobacionId ===
        this.props.producto.nivelAprobacionId &&
      this.props.poaActivo.nivelAprobacionId === 1;
    return resultado;
  };
  consultarProducto = async productoId => {
    await this.props.getProducto(productoId);
   await this.props.calcularPonderacionArea(this.props.producto.actividades);
    this.setState({isLoadingState:false});
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { producto } = nextProps;
    this.setState({
      producto: producto,
      isLoading: false
    });
  }

  dispatchProductos = productoId => async dispatch => {
    dispatch(getProducto(productoId));
  };

  render() {
    const { producto, isLoading } = this.props;

    const {
      productoId,
      descripcion,
      codigoSnip,
      resultado,
      meta,
      indicador,
      ponderacionArea,
      actividades
    } = producto;
    
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          {this.state.isLoadingState? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >

             <div className="form-group">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    value={descripcion}
                    readOnly
                  />
            </div>

                <div className="form-group ">
                  <label htmlFor="descripción">Resultado</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    value={resultado}
                    readOnly
                  />
                </div>

                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="indicador">Indicador</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      value={indicador}
                      readOnly
                    />
                  </div>

                  <div className="form-group col-md-2">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="meta"
                      placeholder="Meta"
                      value={meta}
                      readOnly
                    />
                  </div>
                 
                  <div className="form-group col-md-4">
                    <label htmlFor="ponderacionArea">
                      Ponderación del Producto en la Unidad
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionArea"
                        placeholder="Ponderación del Producto en la Unidad"
                        value={ponderacionArea}
                        readOnly
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef",
                            borderLeft: "2px solid  #bebaba"
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-2">
                     <label htmlFor="codigoSnip">Código SNIP</label>
                     <input
                       type="text"
                       className="form-control form-control-sm text-dark"
                       id="codigoSnip"
                       placeholder="Código SNIP"
                       value={codigoSnip != null ? codigoSnip : ""}
                       readOnly
                     />
                   </div>
                </div>
                {actividades && (
                  <ListadoActividades
                    validarOpcionEditar={this.validarOpcionEditar}
                    productoId={productoId}
                    actividadesprueba={actividades}
                  ></ListadoActividades>
                )}
              </form>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productos: state.productos.productos,
  producto: state.productos.producto,
  isLoading: state.productos.isLoading,
  user: state.auth.signed,
  poaActivo: state.poas.poaActivo
});

const mapDispatchToProps = dispatch => ({
  getProducto: productoId => dispatch(getProducto(productoId)),
  calcularPonderacionArea: actividades =>
    dispatch(calcularPonderacionArea(actividades))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(DetalleProducto, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE"
  ])
);
