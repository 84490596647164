import {
  MOSTRAR_ACTIVIDADES,
  MOSTRAR_ACTIVIDAD,
  LOADING_ACTIVIDAD,
  ACTIVIDAD_SUCCEEDED,
  CREAR_ACTIVIDADES,
  EDITAR_ACTIVIDADES,
  ELIMINAR_ACTIVIDADES,
  ACTIVIDAD_FAILED,
  MOSTRAR_ACTIVIDAD_LOCAL,
  ELIMINAR_PRESUPUESTO,
  PRESUPUESTO_SUCCEEDED,
  PRESUPUESTO_FAILED,
  ADD_PRESUPUESTO,
  TOTAL_PONDERACION_AREA_ACTIVIDADES,
  CREAR_ACTIVIDADES_PRODUCTOS,
  EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
  MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR,
  MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES,
  CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
  ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,

  //NO POA
  LOADING_ACTIVIDAD_NOPOA,
  MOSTRAR_ACTIVIDADES_NOPOA,
  MOSTRAR_ACTIVIDAD_NOPOA,
  ACTIVIDAD_SUCCEEDED_NOPOA,
  CREAR_ACTIVIDADES_NOPOA,
  EDITAR_ACTIVIDADES_NOPOA,
  ELIMINAR_ACTIVIDADES_NOPOA,
  MOSTRAR_ACTIVIDAD_LOCAL_NOPOA,
  ACTIVIDAD_FAILED_NOPOA,
  CREAR_ACTIVIDADES_PRODUCTOS_NOPOA,
  TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA,
  ELIMINAR_PRESUPUESTO_NOPOA,
  PRESUPUESTO_SUCCEEDED_NOPOA,
  PRESUPUESTO_FAILED_NOPOA,
  ADD_PRESUPUESTO_NOPOA
} from "./../types";
import { api_base, api_basev2 } from "./../../config";
import swal from "sweetalert";
import { getData } from "./../../utils/api-client";
import { VolverAlLogin } from "../../utils/utils";

export const getActividades = (productoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividades/productoId/${productoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDADES, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const createActividades = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividades/crear`;
  let urlv2 = api_basev2 + `Actividades/SaveActividad`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(urlv2, JSON.stringify(actividad))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES, payload: resultado.data });
      dispatch({ type: CREAR_ACTIVIDADES_PRODUCTOS, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
      swal(
        "Crear Actividad",
        "Se ha creado la actividad",
        "success"
      );
    })
    .catch((error) => {
      if (error.response) {
              if (error.response.status === 401) {
                VolverAlLogin();
              }
            }
      if (!error.response) {
        window.location.reload();
      }
      window.location.reload();
      dispatch({ type: ACTIVIDAD_FAILED });
    });
};
export const updateActividades = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/editar`;
  let urlv2 = api_basev2 + `Actividades/UpdateActividad`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(urlv2, actividad)
    .then((resultado) => {
      swal("Actividad", "Se ha editado la actividad", "success");
      dispatch({ type: EDITAR_ACTIVIDADES, payload: resultado.data.data });
      
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
      
    })
    .catch((error) => {
      if (error.response) {
              if (error.response.status === 401) {
                VolverAlLogin();
              }
            }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const deleteActividades = (actividadId) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/eliminar`;
  let urlv2 = api_basev2 + `Actividades/DeleteActividad?actividadId=${actividadId}`
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(urlv2)
    .then((resultado) => {
      dispatch({ type: ELIMINAR_ACTIVIDADES, payload: actividadId });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
              if (error.response.status === 401) {
                VolverAlLogin();
              }
            }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};
export const getActividad = (actividadId) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/${actividadId}`;
  let urlv2 = api_basev2 + `Actividades/ObtenerActividad?actividadId=${actividadId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDAD, payload: resultado.data.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
              if (error.response.status === 401) {
                VolverAlLogin();
              }
            }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const getActividadLocal = (actividadId) => async (dispatch) => {
  dispatch({ type: MOSTRAR_ACTIVIDAD_LOCAL, payload: actividadId });
};

export const deletePresupuesto = (presupuesto) => async (dispatch) => {
  let url = `${api_base}api/v1/presupuesto/eliminar`;
  await getData
    .post(url, JSON.stringify(presupuesto.presupuestoId))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_PRESUPUESTO,
        payload: presupuesto.presupuestoId,
      });
      dispatch({ type: PRESUPUESTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
        dispatch({ type: PRESUPUESTO_FAILED });
      }
    });
};

export const addPresupuesto = (presupuesto) => async (dispatch) => {
  dispatch({ type: ADD_PRESUPUESTO, payload: presupuesto });
};

export const calcularPonderacionArea = (listaActividades) => async (
  dispatch
) => {
  let totalPonderacion = 0;

  for (let i = 0; i < listaActividades.length; i++) {
    totalPonderacion += parseFloat(listaActividades[i].ponderacionActividad);
    // console.log(parseFloat(listaActividades[i].ponderacionActividad))
    // console.log(totalPonderacion)
  }
  dispatch({
    type: TOTAL_PONDERACION_AREA_ACTIVIDADES,
    payload: totalPonderacion,
  });
};

export const getActividadesArticuloSeguimientoPorProveedor = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimiento/proveedor/${requerimientoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const getCantidadTotalItemsProveedor = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimiento/cantidadItems/proveedor/${requerimientoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const setActividadesArticuloSeguimientoAdjudicado = (data) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimientoAdjudicado/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const setActividadesArticuloSeguimientoCotizacion = (data) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimiento/asignar_cotizacion_presupuesto`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({ type: EDITAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const setProveedorInsumosAdjudicados = (data) => async (dispatch) => {
  let url = api_base + `api/v1/adjudicados/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (!error.response) {
        window.location.reload();
      }
      window.location.reload();
      dispatch({ type: ACTIVIDAD_FAILED });
    });
};

export const deleteActividadesArticuloSeguimientoAdjudicado = (data) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimientoAdjudicado/delete`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

//NO POA
export const getActividadesNopoa = (productoNoPoaId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesNoPOA/productoNoPoaId/${productoNoPoaId}`;
  dispatch({ type: LOADING_ACTIVIDAD_NOPOA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDADES_NOPOA, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
      }
    });
};

export const createActividadesNopoa = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividadNoPOA/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(actividad))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES_NOPOA, payload: resultado.data });
      dispatch({ type: CREAR_ACTIVIDADES_PRODUCTOS_NOPOA, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (!error.response) {
        dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
      }
      
      dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
    });
};

export const updateActividadesNopoa = (actividadNopoa) => async (dispatch) => {
  let url = api_base + `api/v1/actividadNoPOA/editar`;
  dispatch({ type: LOADING_ACTIVIDAD_NOPOA });
  await getData
    .post(url, actividadNopoa)
    .then((resultado) => {
      dispatch({ type: EDITAR_ACTIVIDADES_NOPOA, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
      }
    });
};

export const deleteActividadesNopoa = (actividadNopoa) => async (dispatch) => {
  let url = api_base + `api/v1/actividadNoPOA/eliminar`;
  dispatch({ type: LOADING_ACTIVIDAD_NOPOA });
  // console.log('actividadNopoa',actividadNopoa)
  await getData
    .post(url, actividadNopoa)
    .then((resultado) => {
      dispatch({ type: ELIMINAR_ACTIVIDADES_NOPOA, payload: actividadNopoa.actividadId });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
      }
    });
};

export const getActividadNopoa = (actividadId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadNoPOA/${actividadId}`;
  dispatch({ type: LOADING_ACTIVIDAD_NOPOA });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDAD_NOPOA, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
      }
    });
};

export const getActividadLocalNopoa = (actividadId) => async (dispatch) => {
  dispatch({ type: MOSTRAR_ACTIVIDAD_LOCAL_NOPOA, payload: actividadId });
};

export const calcularPonderacionAreaNopoa = (listaActividades) => async (
  dispatch
) => {
  let totalPonderacion = 0;

  for (let i = 0; i < listaActividades.length; i++) {
    totalPonderacion += parseFloat(listaActividades[i].ponderacionActividad);
  }
  dispatch({
    type: TOTAL_PONDERACION_AREA_ACTIVIDADES_NOPOA,
    payload: totalPonderacion,
  });
};

export const deletePresupuestoNopoa = (presupuestoNoPoaId) => async (dispatch) => {
  let url = `${api_base}api/v1/presupuesto-no-poa/eliminar`;
  // console.log('presupuesto',presupuestoNoPoaId);

  await getData
    .post(url, {presupuestoNoPoaId : presupuestoNoPoaId} )
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_PRESUPUESTO_NOPOA,
        payload: presupuestoNoPoaId,
      });
      dispatch({ type: PRESUPUESTO_SUCCEEDED_NOPOA });
      window.location.reload();
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (error.response) {
        dispatch({ type: PRESUPUESTO_FAILED_NOPOA });
      }
    });
};

export const addPresupuestoNopoa = (presupuestoNopoa) => async (dispatch) => {
  dispatch({ type: ADD_PRESUPUESTO_NOPOA, payload: presupuestoNopoa });
};

export const guardarInsumosNopoa = (insumo) => async (dispatch) => {
  let url = api_base + `api/v1/presupuesto-no-poa`;

  dispatch({ type: LOADING_ACTIVIDAD_NOPOA });
  await getData
    .post(url, JSON.stringify(insumo))
    .then((resultado) => {
      dispatch({ type: ADD_PRESUPUESTO_NOPOA, payload: insumo });
      dispatch({ type: ACTIVIDAD_SUCCEEDED_NOPOA });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          VolverAlLogin();
        }
      }
      if (!error.response) {
        window.location.reload();
      }
      window.location.reload();
      dispatch({ type: ACTIVIDAD_FAILED_NOPOA });
    });
};