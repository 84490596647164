import React from "react";
import Select from "react-select";
import { Multiselect } from "react-widgets";
import { meses } from "../../utils/mesesByTrimestre"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import swal from "sweetalert";

export const ModalInsumosNoPoa = props => {
  let insumoRef = React.createRef();
  let totalRef = React.createRef();
  let valorRef = React.createRef();
  let cantidadRef = React.createRef();
  let frecuenciaRef = React.createRef();
  let noCuentaRef = React.createRef();
  let subclaseRef = React.createRef();
  let definicionRef = React.createRef();
  let denominacionRef = React.createRef();
  let referenciaInsumoRef = React.createRef();
  let mesesCalendarSelectedRef = React.createRef();
  let mesesCalendar = [];
  let timer = 0;
  let insumoIdRef = React.createRef();
  let number = 0;
  const toggle = () => props.ActivarModalInsumos();

  const validarMesesLista = () => {
    if (props.state.mesesHabilesCalendar && props.state.mesesHabilesCalendar.length > 0) {
      props.state.mesesHabilesCalendar.forEach(element => {
        mesesCalendar = [...mesesCalendar, ...meses[element]]
      });
    } else {
      mesesCalendar = [];
    }
  }

  validarMesesLista();

  const handleInsumo = (newValue, e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const inputValue = newValue;
      if (inputValue !== "") {
        props.consultarInsumosActividad(
          props.state.cuentaFiltro,
          inputValue,
          props.state.operacionFiltro
        );
      }
    }, 2000);

  };

  const calcularValorInsumo = () => {
    if (
      cantidadRef.current !== null &&
      frecuenciaRef.current !== null &&
      valorRef.current !== null &&
      cantidadRef.current.value !== "" &&
      frecuenciaRef.current.value !== "" &&
      valorRef.current !== ""
    ) {
      number =
        cantidadRef.current.value *
        frecuenciaRef.current.value *
        valorRef.current.value;
      totalRef.current.value = number;
    } else {
      if (totalRef.current != null) {
        totalRef.current.value = "";
      }
    }
  };

  const guardarPresupuesto = () => {
    if (validarDatos()) {
      let presupuesto = {
        descripcion: insumoRef.current.value,
        unidadMedida: props.state.unidadMedidaInsumo,
        valor: valorRef.current.value,
        cuenta: noCuentaRef.current.value,
        cantidad: cantidadRef.current.value,
        frecuencia: frecuenciaRef.current.value,
        insumo: insumoRef.current.value,
        referenciaInsumo: referenciaInsumoRef.current.value,
        insumoId: insumoIdRef.current.value,
        subclase: subclaseRef.current.value,
        mesCompra: mesesCalendarSelectedRef.toString(),
        actividadNoPoaId: props.state.actividadNopoaId,
        presupuestoNoPoaId: 0
      };
      props.guardarInsumos(presupuesto);
    }
  };

  const validarDatos = () => {
    if (insumoRef.current === null || insumoRef.current.value === "") {
      swal("Insumos", "El insumo es un valor requerido", "error");

      return false;
    }

    if (cantidadRef.current === null || cantidadRef.current.value === "") {
      swal("Insumos", "La cantidad es un valor requerido", "error");

      return false;
    }

    if (frecuenciaRef.current === null || frecuenciaRef.current.value === "") {
      swal("Insumos", "La frecuencia es un valor requerido", "error");

      return false;
    }
  
    if ((mesesCalendarSelectedRef.current == null && mesesCalendarSelectedRef.length==undefined) ||mesesCalendarSelectedRef.length == 0) {
      swal("Mes Compra", "El mes de la compra del insumo es requerido", "error");

      return false;
    }
    return true;
  };

  const { modalInsumos, maestroInsumos } = props;
  return (
    <Modal isOpen={modalInsumos} toggle={toggle} size="lg" backdrop="static">
      <ModalHeader className="block-example border-bottom " toggle={toggle}>
        Agregar Insumo de la actividad No Poa
      </ModalHeader>
      <ModalBody className="block-example border-bottom ">
        <form>
          <label>Filtros de Búsqueda</label>
          <div >
            <Select
              placeholder="Insumo"
              data={maestroInsumos}
              isLoading={props.state.isLoadingInsumos}
              name="insumoFiltro"
              options={maestroInsumos}
              getOptionLabel={option => option.descripcion}
              getOptionValue={option => option.insumoId}
              onChange={e => {
                insumoRef.current.value = e.descripcion;
                noCuentaRef.current.value = e.catalogoClasificador.cuentaPresupuestaria;
                valorRef.current.value =e.precio ;
                subclaseRef.current.value = e.catalogoClasificador.subclase;
                definicionRef.current.value = e.catalogoClasificador.definicion;
                denominacionRef.current.value = e.catalogoClasificador.subcuentaClasificador.denominacion;
                insumoIdRef.current.value = e.id;
                document.getElementById("valor").value = e.precio;
                props.setStateUnidadMedidaInsumo(e.unidadMedida);
                calcularValorInsumo();
              }}
              onBlur={e => {
                e.preventDefault();
              }}
              onInputChange={e => {
                handleInsumo(e);
              }}
            ></Select>
          </div>
          <div className="form-row mt-1">
            <div className="col-12">
              <label>Insumo</label>
              <input
                type="text"
                ref={insumoRef}
                className="form-control text-dark"
                placeholder="Insumo"
                readOnly
              />
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="cantidad">Cantidad</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control  text-dark"
                    id="cantidad"
                    placeholder="Cantidad"
                    ref={cantidadRef}
                    onKeyUp={() => calcularValorInsumo()}
                    required
                  />
                  {props.state.unidadMedidaInsumo !== "" ? (
                    <div
                      className="input-group-append"
                      title="Unidad de Medida"
                    >
                      <span
                        className="input-group-text text-dark  "
                        style={{
                          backgroundColor: "#e9ecef",
                          borderLeft: "2px solid  #bebaba"
                        }}
                      >
                        {props.state.unidadMedidaInsumo}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="frecuencia">Frecuencia</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="frecuencia"
                  placeholder="Frecuencia"
                  ref={frecuenciaRef}
                  onKeyUp={() => calcularValorInsumo()}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="valor">Valor</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="valor"
                  placeholder="valor"
                  required
                  ref={valorRef}
                  onKeyUp={(e) =>{ valorRef.current.value= e.currentTarget.value; calcularValorInsumo()}}

                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="form-group ">
                <label htmlFor="total">Total</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="total"
                  placeholder="Total"
                  readOnly
                  required
                  ref={totalRef}
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-5">
              <div className="form-group">

                <label htmlFor="unidadResponsable">
                  Mes Compra Insumo
                </label>

                <Multiselect
                  placeholder="--Seleccione el Mes--"
                  data={mesesCalendar}
                  valueField="id"
                  id="mesInsumo"
                  textField="value"
                  defaultValue={[]}
                  onChange={(e) => {
                    mesesCalendarSelectedRef = [];
                    e.map((mes) =>
                      mesesCalendarSelectedRef.push(
                        mes.id
                      )
                    );
                  }}
                />
              </div>
            </div>
            <div className="col-7">
              <div className="form-group ">
                <label htmlFor="referenciaInsumo">Referencia Insumo</label>
                <textarea rows={1} className="form-control  text-dark" id="referenciaInsumo" placeholder="Referencia Insumo" ref={referenciaInsumoRef} ></textarea>
              </div>
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-2">
              <div className="form-group ">
                <label htmlFor="noCuenta">Cuenta</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="noCuenta"
                  placeholder="Cuenta"
                  required
                  ref={noCuentaRef}
                  readOnly
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group ">
                <label htmlFor="subclase">Subclase</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="subclase"
                  placeholder="Subclase"
                  ref={subclaseRef}
                  readOnly
                />
              </div>
            </div>
            <div className="col-8">
              <div className="form-group ">
                <label htmlFor="denominacion">Denominación</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="denominacion"
                  placeholder="Denominación"
                  required
                  ref={denominacionRef}
                  readOnly
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>

            <div className="col-4" hidden>
              <div className="form-group ">
                <label htmlFor="valor">Valor</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="valor"
                  placeholder="Valor"
                  readOnly
                  ref={valorRef}
                  required
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
            <div className="col-4" hidden>
              <div className="form-group ">
                <label htmlFor="insumoId">InsumoId</label>
                <input
                  type="text"
                  className="form-control  text-dark"
                  id="insumoId"
                  placeholder="insumoId"
                  readOnly
                  ref={insumoIdRef}
                />
                <div className="invalid-feedback">
                  Este es un campo obligatorio
                </div>
              </div>
            </div>
          </div>
          <div className="form-row mt-1">
            <div className="col-12">
              <div className="form-group ">
                <label htmlFor="noCuenta">Definición</label>
                <textarea rows={2} className="form-control  text-dark" id="definicion" placeholder="Definición" ref={definicionRef} readOnly></textarea>
              </div>
            </div>

          </div>
        </form>
      </ModalBody>
      <ModalFooter className="block-example border-bottom ">
        <button
          className="btn btn-info"
          onClick={e => {
            e.preventDefault();
            guardarPresupuesto();
          }}
        >
          Guardar
        </button>
        <button
          className="btn btn-danger"
          onClick={e => {
            e.preventDefault();
            props.ActivarModalInsumos();
          }}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  );
};
