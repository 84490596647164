import React, { Component } from "react";

import { connect } from "react-redux";
import {
  updateProducto,
  getProducto,
  calcularPonderacionGeneral,
} from "../../redux/actions/productos-actions";

import {
  getEjesEstrategicos,
  getEstrategias,
  getObjetivosEstrategicos,
} from "../../redux/actions/planesEstrategicos-actions";
import { getInvolucrados } from "../../redux/actions/involucrados-actions";
import {
  getAreasOrganizacionales,
  getAreasOrganizacionalesResponsables,
} from "../../redux/actions/areas-actions";
import {
  EjeEstrategico,
  ObjetivoEstrategico,
  Estrategia,
} from "./PlanesEstrategicos";

import { getProductoProgramatica } from "../../redux/actions/productoProgramatica-actions";

import { getPonderacionArea } from "./../../redux/actions/ponderacion-areas-action";
import { Authorization } from "./../../utils/Authorization";

import { LOADING_PRODUCTO, PRODUCTO_SUCCEEDED } from "../../redux/types";

import { Multiselect,DropdownList } from "react-widgets";
import ReactTooltip from "react-tooltip";

import { Container } from "reactstrap";
import swal from "sweetalert";

import Loading from "../utils/Loading";

class EditarProducto extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoadingState: false,
      ejesEstrategicos: [],
      objetivosEstrategicos: [],
      estrategias: [],
      productoEdit: true,
      resultadoEdit: true,
      indicadorEdit: true,
      metaEdit: false,
      ponderacionAreaEdit: true,
      ejeEstrategicoEdit: true,
      objetivoEstrategicoEdit: true,
      estrategiaEdit: true,
      unidadResponsableEdit: true,
      involucradoEdit: true,
      cooperacionExternaEdit: true,
      validarTotalPonderacion: false,
      validarTotalPonderacionGeneral: false,
      // accionMitigacionEdit: true,
      // riesgoAsociadosEdit: true,
      snipEdit: true,
      donanteEdit: true,
      mostrarOcultarCodigoSnip: null,
      productoProgramaticaEdit: true,
      productoProgramaticaProductos:{}
    };
  }

  productoRef = React.createRef();
  resultadoRef = React.createRef();
  indicadorRef = React.createRef();
  metaRef = React.createRef();
  ponderacionAreaRef = React.createRef();
  ejeEstrategicoRef = React.createRef();
  objetivoGeneralRef = React.createRef();
  estrategiaRef = React.createRef();
  unidadResponsableRef = React.createRef();
  involucradosRef = React.createRef();
  // cooperacionRef = React.createRef();
  ponderacionGeneralRef = React.createRef();
  // donanteRef = React.createRef();
  snipRef = React.createRef();
  codigoSnipRef = React.createRef();
  // accionMitigacionRef = React.createRef();
  // riesgoAsociadoRef = React.createRef();
  productoProgramaticaRef = React.createRef();

  async componentDidMount() {
    const { productoId } = this.props.match.params;

    const { roles } = this.props.user;    
    // Validar que el usuario posea al menos estos tres roles pertenecientes a planificación
    // Gestor del POA
    // Analista del POA
    // Administrador general

    for (let r = 0; r < roles.length; r++) {
      if (
        roles[r] === "Gestor del POA" ||
        roles[r] === "Analista del POA" ||
        roles[r] === "Administrador general"
      ) {
        this.setState({
          snipEdit: false,
          productoProgramaticaEdit: false,
        });
        break;
      }
    }

    this.loading();
    this.setState({ isLoadingState: true });
    await this.cargarData(productoId);

    const {
      ejesEstrategicos,
      objetivosEstrategicos,
      estrategias,
    } = this.props.planesEstrategicos;
    this.prueba(ejesEstrategicos, objetivosEstrategicos, estrategias);
  }

  loading = () => async (dispatch) => {
    dispatch({ type: LOADING_PRODUCTO });
  };
  
  cargarData = async (productoId) => {
    await this.props.getProducto(productoId).then((resultado) => {
      this.ActivarCamposEdit();
      this.props
        .getPonderacionArea(
          this.props.poaActivo.poaId,
          this.props.producto.unidadResponsableProductos[0].areaOrganizacionalId
        )
        .then(() => {
          this.calcularPonderacionArea();
        });
    });

    await this.props.getEjesEstrategicos();
    await this.props.getEstrategias();
    await this.props.getProductoProgramatica();
    this.setState({productoProgramaticaProductos:[this.props.productoProgramaticas.find(x=> x.id==this.props.producto.programaticaId)]})
    await this.props.getObjetivosEstrategicos();
    await this.props.getInvolucrados();
    await this.props.getAreasOrganizacionalesResponsables(this.props.user.areaOrganizacional.areaOrganizacionalId);
    await this.validarInput();
    await this.calcularPonderacionTotalProductos();
    await this.validarPonderacionGeneral();
    this.props.calcularPonderacionGeneral(this.props.poaActivo.poaId);


    this.productoProgramaticaRef = [];
    this.state.productoProgramaticaProductos.map(programaticas => {
      this.productoProgramaticaRef.push(programaticas)
      
      }) 
      this.setState({ isLoadingState: false });
  };

  validarInput = () => {
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  };

  editarProducto = (e) => {
    e.preventDefault();
    if (
      this.validarProducto(
        this.productoRef.current.value,
        this.resultadoRef.current.value,
        this.indicadorRef.current.value,
        this.metaRef.current.value,
        this.ponderacionAreaRef.current.value,
        this.ejeEstrategicoRef.current.value,
        this.objetivoGeneralRef.current.value,
        this.estrategiaRef.current.value,
        this.unidadResponsableRef,
        this.involucradosRef,
        this.snipRef.current.value,
        this.codigoSnipRef.current.value,
        this.productoProgramaticaRef
      )
    ) {
      const { productoId } = this.props.match.params;
     //console.log(typeof(this.productoProgramaticaRef))
      if (this.productoProgramaticaRef[0] == undefined) {
        this.productoProgramaticaRef = [];
      }
      let programatica= this.productoProgramaticaRef.length>0 ?this.productoProgramaticaRef[0].programatica:null;
      let programaticaId= this.productoProgramaticaRef.length>0 ?this.productoProgramaticaRef[0].id:null;
      let producto = {
        productoId: productoId,
        descripcion: this.productoRef.current.value,
        resultado: this.resultadoRef.current.value,
        indicador: this.indicadorRef.current.value,
        meta: this.metaRef.current.value,
        ponderacionArea: this.ponderacionAreaRef.current.value,
        ejeEstrategico:
          this.ejeEstrategicoRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.ejeEstrategicoRef.current.value).ejeEstrategicoId
            : null,
        objetivoEstrategico:
          this.objetivoGeneralRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.objetivoGeneralRef.current.value)
                .objetivoEstrategicoId
            : null,
        estrategia:
          this.estrategiaRef.current.value !== "--Seleccione una opción--"
            ? JSON.parse(this.estrategiaRef.current.value).estrategiaId
            : null,
        programatica:programatica,
        programaticaId: programaticaId,
        unidadResponsables: this.unidadResponsableRef,
        involucrados: this.involucradosRef,
        poaId: this.props.poaActivo.poaId,
        usuarioId: 2,
        ponderacionGeneral: this.ponderacionGeneralRef.current.value !== ""
                              ? this.ponderacionGeneralRef.current.value
                              : null,
        // accionMitigacion: this.accionMitigacionRef.current.value,
        // riesgoAsociado: this.riesgoAsociadoRef.current.value,
        snip: this.snipRef.current.value == "true" ? true : false,
        codigoSnip: this.codigoSnipRef.current.value,
        email: this.props.user.email,
        nivelAprobacionId: this.props.producto.nivelAprobacionId
      };
// console.log(producto)
      this.props
        .updateProducto(producto)
        .then((data) => {
          this.props.match.params.codigoSnip
            ? this.props.history.push("/admin/administracion/productos")
            : this.props.history.goBack();
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  validarProducto = (
    producto,
    resultado,
    indicador,
    meta,
    ponderacionArea,
    ejeEstrategico,
    objetivoGeneral,
    estrategia,
    unidadResponsable,
    involucrados,
    cooperacion,
    snip,
    codigoSnip,
    productoProgramatica
  ) => {
    if (producto === "") {
      swal(
        "Editar Producto",
        "El campo producto es un valor requerido",
        "error"
      );
      return false;
    }
    if (resultado === "") {
      swal(
        "Editar Producto",
        "El campo resultado es un valor requerido",
        "error"
      );
      return false;
    }
    if (indicador === "") {
      swal(
        "Editar Producto",
        "El campo indicador es un valor requerido",
        "error"
      );

      return false;
    }
    if (meta === "") {
      swal("Editar Producto", "El campo meta es un valor requerido", "error");

      return false;
    }
    if (ponderacionArea === "") {
      swal(
        "Editar Producto",
        "El campo ponderación del producto en la unidad es un valor requerido",
        "error"
      );

      return false;
    }
    if (ejeEstrategico === "--Seleccione una opción--") {
      swal(
        "Editar Producto",
        "El campo eje estrategico es un valor requerido",
        "error"
      );

      return false;
    }
    if (objetivoGeneral === "--Seleccione una opción--") {
      // alert("El campo objetivo estratégico es un valor requerido");
      swal(
        "Editar Producto",
        "El campo objetivo estratégico es un valor requerido",
        "error"
      );

      return false;
    }
    if (estrategia === "--Seleccione una opción--") {
      // alert("El campo estrategico es un valor requerido");
      swal(
        "Editar Producto",
        "El campo estrategico es un valor requerido",
        "error"
      );

      return false;
    }
    if (unidadResponsable === "--Seleccione una opción--") {
      swal(
        "Editar Producto",
        "El campo objetivo estratégico es un valor requerido",
        "error"
      );

      return false;
    }
    if (unidadResponsable.length === 0) {
      swal(
        "Editar Producto",
        "El campo unidad responsable es un valor requerido",
        "error"
      );

      return false;
    }

    return true;
  };

  tieneSnip = () => {
    const snipValue = this.snipRef.current.value;
    const existeSnip = snipValue === "true";

    // Limpiar el campo codigo SNIP si el usuario elige "No"
    if (snipValue === "false") {
      this.codigoSnipRef.current.value = "";
    }

    this.setState({ mostrarOcultarCodigoSnip: !existeSnip });
  };

  calcularPonderacionArea = () => {
    if (this.ponderacionGeneralRef.current !== null) {
      this.ponderacionGeneralRef.current.value =
        this.ponderacionAreaRef.current.value *
        (this.props.ponderacionArea.ponderacion / 100);
    }
  };

  activarNota = (property) => {
    let { notaProductos } = this.props.producto;
    let indice = notaProductos.length - 1;
    let resultado = false;
    if (notaProductos.length > 0 && notaProductos[indice][property] !== null) {
      resultado = true;
    }
    return resultado;
  };

  validarNotaProductosActual = (notaProductos, areaOrganizacional, user) => {
    let productoEdit = true;
    let resultadoEdit = true;
    let indicadorEdit = true;
    let metaEdit = true;
    let ponderacionAreaEdit = true;
    let ejeEstrategicoEdit = true;
    let objetivoEstrategicoEdit = true;
    let estrategiaEdit = true;
    let unidadResponsableEdit = true;
    let involucradoEdit = true;
    let cooperacionExternaEdit = true;
    let accionMitigacionEdit = true;
    let riesgoAsociadoEdit = true;
    let codigoSnipEdit = true;
    let donanteEdit = true;
    let productoProgramaticaEdit = true;

    const indice = notaProductos.length - 1;
    if (
      notaProductos.length > 0 &&
      areaOrganizacional.areaOrganizacionalId ===
        user.areaOrganizacional.areaOrganizacionalId
    ) {
      const lastNota = notaProductos[indice];

      if (lastNota.descripcion !== null) productoEdit = false;
      if (lastNota.resultado !== null) resultadoEdit = false;
      if (lastNota.indicador !== null) indicadorEdit = false;
      if (lastNota.meta !== null) metaEdit = false;
      if (lastNota.ponderacionArea !== null) ponderacionAreaEdit = false;
      if (lastNota.ejeEstrategico !== null) ejeEstrategicoEdit = false;
      if (lastNota.cooperacionExterna !== null) cooperacionExternaEdit = false;
      if (lastNota.objetivoEstrategico !== null)
        objetivoEstrategicoEdit = false;
      if (lastNota.estrategia !== null) estrategiaEdit = false;
      if (lastNota.unidadResponsable !== null) unidadResponsableEdit = false;
      if (lastNota.involucrados !== null) involucradoEdit = false;
      if (lastNota.accionMitigacion !== null) accionMitigacionEdit = false;
      if (lastNota.riesgoAsociado !== null) riesgoAsociadoEdit = false;
      if (lastNota.codigoSnip !== null) codigoSnipEdit = false;
    }

    this.setState({
      productoEdit,
      resultadoEdit,
      indicadorEdit,
      metaEdit,
      ponderacionAreaEdit,
      ejeEstrategicoEdit,
      objetivoEstrategicoEdit,
      estrategiaEdit,
      unidadResponsableEdit,
      involucradoEdit,
      cooperacionExternaEdit,
      riesgoAsociadoEdit,
      accionMitigacionEdit,
      codigoSnipEdit,
    });
  };

  ActivarCamposEdit = () => {
    let { notaProductos, modoEditar, areaOrganizacional } = this.props.producto;

    if (modoEditar) {
      this.validarNotaProductosActual(
        notaProductos,
        areaOrganizacional,
        this.props.user
      );
    } else {
      if (this.props.match.params.codigoSnip) {
        this.setState({
          productoEdit: true,
          resultadoEdit: true,
          indicadorEdit: true,
          metaEdit: true,
          ponderacionAreaEdit: true,
          ejeEstrategicoEdit: true,
          objetivoEstrategicoEdit: true,
          estrategiaEdit: true,
          unidadResponsableEdit: true,
          involucradoEdit: true,
          cooperacionExternaEdit: true,
          accionMitigacionEdit: true,
          riesgoAsociadoEdit: true,
          donanteEdit: true,
        });
      } else {
        this.setState({
          productoEdit: false,
          resultadoEdit: false,
          indicadorEdit: false,
          metaEdit: false,
          ponderacionAreaEdit: false,
          ejeEstrategicoEdit: false,
          objetivoEstrategicoEdit: false,
          estrategiaEdit: false,
          unidadResponsableEdit: false,
          involucradoEdit: false,
          cooperacionExternaEdit: false,
          accionMitigacionEdit: false,
          riesgoAsociadoEdit: false,
          donanteEdit: false,
        });
      }
    }
  };
  prueba = (ejesEstrategicos, objetivosEstrategicos, estrategias) => {
    if (
      this.props.planesEstrategicos.objetivosEstrategicos.length > 0 &&
      this.state.objetivosEstrategicos.length === 0
    ) {
      this.setState(
        { ejesEstrategicos, objetivosEstrategicos, estrategias },
        () => {     
          if (
            ejesEstrategicos.length === 1 &&
            this.state.ejesEstrategicos.length > 0
          ) {
            this.setState(
              {
                objetivosEstrategicos: this.state.ejesEstrategicos[0]
                  .objetivosEstrategicos,
              },
              () => {
                if (this.state.objetivosEstrategicos.length === 1) {
                  this.setState({
                    estrategias: this.state.objetivosEstrategicos[0]
                      .estrategias,
                  });
                }
              }
            );
          }
        }
      );
    }
  };

  calcularPonderacionTotalProductos = () => {
    let ponderacionAreaProps =
      this.props.totalPonderacionProductos -
      this.props.producto.ponderacionArea;
    let ponderacionArea =
      this.ponderacionAreaRef.current !== null &&
      this.ponderacionAreaRef.current.value !== ""
        ? this.ponderacionAreaRef.current.value
        : 0;
    let total = parseFloat(ponderacionAreaProps) + parseFloat(ponderacionArea);
    if (total <= 100) {
      this.setState({ validarTotalPonderacion: true });
    } else {
      this.setState({ validarTotalPonderacion: false });
      swal(
        "Editar Producto",
        "Excede el límite de ponderación permitido para los productos, actualmente tiene " +
          total +
          "%",
        "error"
      );
    }
  };

  validarPonderacionGeneral = () => {
    // let ponderaciongeneral =
    //   this.props.ponderacionGeneral - this.props.producto.ponderacionGeneral;
    // let ponderacionGeneralNueva =
    //   this.ponderacionGeneralRef.current !== null &&
    //   this.ponderacionGeneralRef.current.value !== ""
    //     ? this.ponderacionGeneralRef.current.value
    //     : 0;
    // let total =
    //   parseFloat(ponderaciongeneral) + parseFloat(ponderacionGeneralNueva);
    let ponderaciongeneral =
    this.props.totalPonderacionProductos - this.props.producto.ponderacionArea;
    let ponderacionGeneralNueva =
      this.ponderacionGeneralRef.current !== null &&
      this.ponderacionGeneralRef.current.value !== ""
        ? this.ponderacionGeneralRef.current.value
        : 0;
    let total =
      parseFloat(ponderaciongeneral) + parseFloat(ponderacionGeneralNueva);

  //   let ponderacionArea =
  //   this.ponderacionAreaRef.current !== null &&
  //   this.ponderacionAreaRef.current.value !== ""
  //     ? this.ponderacionAreaRef.current.value
  //     : 0;
  // let total =
  //   this.props.totalPonderacionProductos + parseFloat(ponderacionArea);
    if (total <= 100) {
      this.setState({ validarTotalPonderacionGeneral: true });
    } else {
      this.setState({ validarTotalPonderacionGeneral: false });
    }
  };

  render() {
    const {
      involucrados,
      areasOrganizacionales,
      productoProgramaticas,
      isLoading,
    } = this.props;

    const {
      descripcion,
      resultado,
      indicador,
      meta,
      ponderacionArea,
      ejeEstrategico,
      estrategia,
      objetivoEstrategico,
      unidadResponsableProductos,
      involucradosProductos,
      ponderacionGeneral,
      notaProductos,
      accionMitigacion,
      riesgoAsociado,
      snip,
      codigoSnip,
      programatica,
      programaticaId
    } = this.props.producto;
    
    //Se carga la data de los involucrados en el ref por si no se ha realizado ningun cambio.
    this.unidadResponsableRef = [];
    unidadResponsableProductos.map(unidad => {
      this.unidadResponsableRef.push(
        unidad.areaOrganizacionalId
      );
    });

    this.involucradosRef = [];
    involucradosProductos.map(invo => {
      this.involucradosRef.push(
        invo.involucradoId
      )
    })
    
    
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!ejeEstrategico ||isLoading ||this.state.isLoadingState ? (
          <Loading></Loading>
        ) : (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.editarProducto}
                noValidate
              >
                       <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="ejeEstrategico">Eje Estratégico</label>
                      {this.activarNota("ejeEstrategico") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .ejeEstrategicos
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="ejeEstrategico"
                      ref={this.ejeEstrategicoRef}
                      disabled={this.state.ejeEstrategicoEdit}
                    >
                      {this.state.ejesEstrategicos.length > 1 && (
                        <option
                          value={JSON.stringify(ejeEstrategico)}
                          defaultValue={ejeEstrategico}
                        >
                          {ejeEstrategico.descripcion}
                        </option>
                      )}
                      {this.state.ejesEstrategicos.map((ejeEstrategico) => (
                        <EjeEstrategico
                          key={ejeEstrategico.ejeEstrategicoId}
                          EjeEstrategico={ejeEstrategico}
                        ></EjeEstrategico>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="objetivoGeneral">
                        Objetivo Estratégico
                      </label>
                      {this.activarNota("objetivoEstrategico") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .objetivoEstrategico
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="objetivoGeneral"
                      ref={this.objetivoGeneralRef}
                      disabled={this.state.objetivoEstrategicoEdit}
                    >
                      {this.state.objetivosEstrategicos.length > 0 && (
                        <option
                          value={JSON.stringify(objetivoEstrategico)}
                          defaultValue={objetivoEstrategico}
                        >
                          {objetivoEstrategico.descripcion}
                        </option>
                      )}
                      {this.state.objetivosEstrategicos.map(
                        (objetivoEstrategico) => (
                          <ObjetivoEstrategico
                            key={objetivoEstrategico.objetivoEstrategicoId}
                            objetivoEstrategico={objetivoEstrategico}
                          ></ObjetivoEstrategico>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="estrategia">Estrategia</label>
                      {this.activarNota("estrategia") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1].estrategia
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="estrategia"
                      ref={this.estrategiaRef}
                      disabled={this.state.estrategiaEdit}
                    >
                      {this.state.estrategias.length > 0 && (
                        <option
                          value={JSON.stringify(estrategia)}
                          defaultValue={estrategia}
                        >
                          {estrategia.descripcion}
                        </option>
                      )}
                      {this.state.estrategias.map((estrategia) => (
                        <Estrategia
                          key={estrategia.estrategiaId}
                          estrategia={estrategia}
                        ></Estrategia>
                      ))}
                    </select>
                  </div>
                  <div className="form-group input-group-sm col-md-6 ">
                    <div>
                      <label htmlFor="unidadResponsable">
                        Unidad Responsable
                      </label>
                      {this.activarNota("unidadResponsable") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .unidadResponsable
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <Multiselect
                      readOnly={this.state.unidadResponsableEdit}
                      placeholder="--Seleccione las unidades responsables--"
                      data={areasOrganizacionales}
                      valueField="areaOrganizacionalId"
                      id="unidadResponsable"
                      textField="nombre"
                      defaultValue={unidadResponsableProductos}
                      onChange={(e) => {             
                        this.unidadResponsableRef = [];
                        e.map((area) =>
                          this.unidadResponsableRef.push(
                            area.areaOrganizacionalId
                          )
                        );
                      }}
                      
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="form-group ">
                  <div>
                    <label className="" htmlFor="descripción">
                      Producto
                    </label>
                    {this.activarNota("descripcion") && (
                      <button
                        type="button"
                        onClick={(e) => e.preventDefault()}
                        data-tip={
                          notaProductos[notaProductos.length - 1].descripcion
                        }
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    ref={this.productoRef}
                    defaultValue={descripcion}
                    required
                    readOnly={this.state.productoEdit}
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group">
                  <div>
                    <label htmlFor="resultado">Resultado (s)</label>
                    {this.activarNota("resultado") && (
                      <button
                        type="button"
                        data-tip={
                          notaProductos[notaProductos.length - 1].resultado
                        }
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                  </div>
                  <textarea
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    ref={this.resultadoRef}
                    defaultValue={resultado}
                    required
                    readOnly={this.state.resultadoEdit}
                  ></textarea>
                </div>
                {/* <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="riesgoAsociado">Riesgo Asociados</label>
                      {this.activarNota("riesgoAsociado") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .riesgoAsociado
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <textarea
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="riesgoAsociado"
                      placeholder="Riesgo Asociado"
                      ref={this.riesgoAsociadoRef}
                      defaultValue={riesgoAsociado}
                      readOnly={this.state.riesgoAsociadoEdit}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="accionMitigacion">
                        Acción de Mitigación
                      </label>
                      {this.activarNota("accionMitigacion") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .accionMitigacion
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <textarea
                      className="form-control form-control-sm  text-dark"
                      id="accionMitigacion"
                      placeholder="Acción de Mitigación"
                      ref={this.accionMitigacionRef}
                      defaultValue={accionMitigacion}
                      readOnly={this.state.accionMitigacionEdit}
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="indicador">Indicador (es)</label>
                      {this.activarNota("indicador") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1].indicador
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      ref={this.indicadorRef}
                      defaultValue={indicador}
                      readOnly={this.state.indicadorEdit}
                    />
                  </div>
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="meta">Meta</label>
                      {this.activarNota("meta") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1].meta
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm  text-dark"
                      id="meta"
                      placeholder="Meta del producto"
                      ref={this.metaRef}
                      defaultValue={meta}
                      readOnly={this.state.metaEdit}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="ponderacionArea">
                        Ponderación del Producto en la Unidad
                      </label>
                      {this.activarNota("ponderacionArea") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .ponderacionArea
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionArea"
                        placeholder="Ponderación del Producto en la Unidad"
                        ref={this.ponderacionAreaRef}
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.calcularPonderacionTotalProductos();
                          this.calcularPonderacionArea();
                        }}
                        defaultValue={ponderacionArea}
                        readOnly={this.state.ponderacionAreaEdit}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 ">
                    <div>
                      <label htmlFor="ponderacionGeneral">
                        Ponderación General
                      </label>
                      {this.activarNota("ponderacionGeneral") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1]
                              .ponderacionGeneral
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionGeneral"
                        placeholder="Ponderacion General del Producto"
                        ref={this.ponderacionGeneralRef}
                        defaultValue={ponderacionGeneral}
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.validarPonderacionGeneral();
                        }}
                        disabled={true}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef",
                            borderLeft: "2px solid  #bebaba",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
         
                <div className="row">
                  <div className="form-group input-group-sm col-md-6">
                    <div>
                      <label htmlFor="involucrados">Involucrados</label>
                      {this.activarNota("involucrados") && (
                        <button
                          type="button"
                          data-tip={
                            notaProductos[notaProductos.length - 1].involucrados
                          }
                          data-event="click"
                          className="btn btn-sm btn-danger float-right"
                        >
                          <i className="ni ni-chat-round"></i>
                        </button>
                      )}
                    </div>
                    <Multiselect
                      readOnly={this.state.involucradoEdit}
                      placeholder="--Seleccione los involucrados--"
                      data={involucrados}
                      valueField="involucradoId"
                      defaultValue={involucradosProductos}
                      textField="descripcion"
                      id="involucrados"
                      onChange={(e) => {               
                        this.involucradosRef = [];
                        e.map((involucrado) =>
                          this.involucradosRef.push(involucrado.involucradoId)
                        );
                      }}
                      minLength={3}
                      required
                    />
                  </div>

                  <div className="form-group input-group-sm col-md-6">
                    <div>
                      <label htmlFor="productoProgramatica">
                        Producto Programática
                      </label>
                    </div>
                    <Multiselect
                      readOnly={this.state.productoProgramaticaEdit}
                      placeholder="--Seleccione la programática--"
                      singleSelect={true}
                      data={productoProgramaticas}
                      valueField="id"
                      defaultValue={this.state.productoProgramaticaProductos[0] == undefined ? null : this.state.productoProgramaticaProductos}
                      textField="programatica"
                      id="productoProgramatica"
                      onChange={(e) => {      
                        
                        this.productoProgramaticaRef = [];
                        e.forEach((productoProgramatica) => {
                          
                          this.productoProgramaticaRef.push(productoProgramatica);
                        });
                      }}
                      minLength={3}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="snip">¿SNIP?</label>
                    <select
                      className="form-control form-control-sm text-dark"
                      id="snip"
                      ref={this.snipRef}
                      onChange={this.tieneSnip}
                      defaultValue={snip}
                      disabled={this.state.snipEdit}
                    >
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>

                  {/* TODO: si el select es igual a si , existe un codigo snip, habilitar este campo */}
                  <div className="form-group col-md-6">
                    <label htmlFor="codigo_snip">Código SNIP</label>
                    <input
                      type="text"
                      className="form-control form-control-sm  text-dark"
                      id="codigo_snip"
                      placeholder="Código SNIP"
                      ref={this.codigoSnipRef}
                      defaultValue={codigoSnip}
                      readOnly={
                        this.state.mostrarOcultarCodigoSnip === null
                          ? snip
                            ? false
                            : true
                          : this.state.mostrarOcultarCodigoSnip
                      }
                      disabled={this.state.snipEdit}
                    />
                  </div>
                </div>
                {this.state.validarTotalPonderacion &&
                this.state.validarTotalPonderacionGeneral ? (
                  <button type="submit" className="btn btn-primary">
                    Guardar Cambios
                  </button>
                ) : (
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="btn btn-primary opacity"
                    title="Excede el límite de ponderación permitido para los productos"
                  >
                    Guardar Cambios
                  </button>
                )}
              </form>
            </div>
            <ReactTooltip globalEventOff="click" />
            <ReactTooltip />
          </Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    poaActivo: state.poas.poaActivo,
    planesEstrategicos: state.planesEstrategicos,
    involucrados: state.involucrados.involucrados,
    areasOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
    productoProgramaticas: state.productoProgramaticas.programaticas,
    producto: state.productos.producto,
    // isLoading: state.productos.isLoading,
    user: state.auth.signed,
    totalPonderacionProductos: state.productos.totalPonderacionProductos,
    ponderacionGeneral: state.productos.totalPonderacionGeneralProductos,
    ponderacionArea: state.ponderacionAreas.ponderacionArea,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getEjesEstrategicos: () => dispatch(getEjesEstrategicos()),
  getProductoProgramatica: () => dispatch(getProductoProgramatica()),
  getEstrategias: () => dispatch(getEstrategias()),
  getObjetivosEstrategicos: () => dispatch(getObjetivosEstrategicos()),
  getInvolucrados: () => dispatch(getInvolucrados()),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getAreasOrganizacionalesResponsables: (areaOrganizacionalId) =>
    dispatch(getAreasOrganizacionalesResponsables(areaOrganizacionalId)),
  getProducto: (productoId) => dispatch(getProducto(productoId)),
  updateProducto: (producto) => dispatch(updateProducto(producto)),
  calcularPonderacionGeneral: (poaId) =>
    dispatch(calcularPonderacionGeneral(poaId)),
  getPonderacionArea: (poaId, areaOrganizacionalId) =>
    dispatch(getPonderacionArea(poaId, areaOrganizacionalId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarProducto, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Gestor del POA",
    "MAE",
  ])
);
