import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteActividadesNopoa,
  getActividadNopoa
} from "../../redux/actions/actividades-actions";
import ReactTooltip from 'react-tooltip';
import numbro from "numbro";

const ActividadAdministracionNoPoa = (props) => {
  const {
    actividadNoPoaId,
    descripcion,
    presupuestoNopoa,
    t1,
    t2,
    t3,
    t4,
    medioVerificacion,
    ponderacionActividad,
    notasActividad
  } = props.actividadNopoa;
//console.log("props.actividadNopoa",props.actividadNopoa)
  const cargarActividad = () => async (dispatch) => {
    props.getActividadNopoa(props.actividadNopoa.actividadNoPoaId);
  };

  const truncateString = (str) => {
    let num = 50;

    return str && str.length > num ? str.slice(0, num) + "..." : str;
  };

  const eliminarActividad = () => {
    let confirmed = window.confirm("¿Está seguro de eliminar la actividad?");
    if (confirmed) {
      let actividad = {
        actividadNoPOAId: props.actividadNopoa.actividadNoPoaId,
        productoNoPOAId: props.productoNoPoaId,
      };
      props.deleteActividadesNopoa(actividad).then(() => window.location.reload());
    }
  };

  return (

    // <tr>
    //   <td title={descripcion}>{truncateString(descripcion)}</td>
    //   {/* <td>{ponderacionActividad}</td>
    //   <td title={medioVerificacion?.descripcion || ''}>{truncateString(medioVerificacion?.descripcion || '', 35)}</td> */}
    //   <td>
    //   {'RD$ '+numbro(presupuestoNopoa.reduce((value, objPresupuesto)=>{ return value +(objPresupuesto.valor*objPresupuesto.cantidad*objPresupuesto.frecuencia)},0)
    //   ).format({thousandSeparated:true}) }
    //   </td>
    //   <td>{t1?t1:0}</td>
    //   <td>{t2?t2:0}</td>
    //   <td>{t3?t3:0}</td>
    //   <td>{t4?t4:0}</td>
    // </tr>
    <tr>
      <td title={descripcion}>{truncateString(descripcion)}</td>
      <td>
           {'RD$ '+numbro(presupuestoNopoa.reduce((value, objPresupuesto)=>{ return value +(objPresupuesto.valor*objPresupuesto.cantidad*objPresupuesto.frecuencia)},0)
           ).format({thousandSeparated:true}) }
      </td>
      <td>{t1?t1:0}</td>
      <td>{t2?t2:0}</td>
      <td>{t3?t3:0}</td>
      <td>{t4?t4:0}</td>
      <td>
        <ReactTooltip globalEventOff='click' />
        <ReactTooltip/>
        <Link
          to={{
            pathname: `/admin/actividadNoPoa/detalle/${actividadNoPoaId}`,
            state: `/admin/actividadNoPoa/detalle/:actividadNoPoaId`,
          }}
          className="btn btn-sm btn-warning"
        >
          Detalle
        </Link>
      </td>
    </tr>
  );
};

export default connect(null, { deleteActividadesNopoa, getActividadNopoa })(ActividadAdministracionNoPoa);
