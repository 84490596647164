import swal from "sweetalert";
import { api_base, api_basev2 } from "./../../config";
import {
  EDITAR_USUARIO,
  LOADING_USUARIOS,
  LOADING_USUARIO,
  MOSTRAR_USUARIO,
  MOSTRAR_USUARIOS,
  USUARIOS_SUCCEEDED,
  USUARIOS_FAILED,
  MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS
} from "./../types";
import { getData } from "../../utils/api-client";

export const getListaUsuarios = () => async (dispatch) => {
  const url = api_base + `api/v1/usuarios`;
  let urlv2 = api_basev2 + "Usuarios/ObtenerUsuarios"
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIOS, payload: resultado.data.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};
export const getUsuario = (email) => async (dispatch) => {
  const url = api_base + `api/v1/usuarios/${email}`;
  const urlv2 = api_basev2;


  dispatch({ type: LOADING_USUARIO });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: JSON.parse(resultado.data.data) });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};
export const getUsuarioEntity = (usuarioId) => async (dispatch) => {
  const url = `${api_base}api/v1/usuarios/usuarioid/${usuarioId}`;
  let urlv2 = api_basev2 + `Usuarios/ObtenerUsuarioPorId?id=${usuarioId}`;
  dispatch({ type: LOADING_USUARIO });
  await getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: resultado.data.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      dispatch({ type: MOSTRAR_USUARIO, payload: {} });
      if (error) {
        dispatch({ type: USUARIOS_FAILED });
      }
    });
};

export const getListaAnalistasAsignados = () => async (dispatch) => {
  const url = api_base + `api/v1/usuarios/analistas/asignados`;

  dispatch({ type: LOADING_USUARIOS });
  await getData
    .get(url)
    .then((resultado) => {

      dispatch({ type: MOSTRAR_USUARIOS_ANALISTAS_ASIGNADOS, payload: resultado.data });
      dispatch({ type: USUARIOS_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({type: USUARIOS_FAILED});
      }
    });
};

export const createUsuario = (usuario) => async (dispatch) => {
  let url = api_base + `api/v1/usuarios/create`;
  let urlv2 = api_basev2 + "Usuarios/AgregarUsuario";
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(urlv2, JSON.stringify(usuario))
    .then((resultado) => {
      swal("Crear Usuario", "Usuario creado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
      return resultado.data;
    })
    .catch((error) => {
      // console.log(resultado);
      if (error) {
        swal("Crear Usuario", "El usuario no pudo ser creado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};

export const updateStatusUsuario = (usuario) => async (dispatch) => {
  // let usuarios = {
  //   email: usuario.email,
  //   comentario: !usuario.activo? "El usuario fue activado":"El usuario fue desactivado",
  //   estatus: !usuario.activo,
  // };

  let urlv2 = api_basev2 + "Usuarios/ActualizarEstado";
  let url = api_base + `api/v1/usuarios/activar`;
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(urlv2, usuario.email)
    .then((resultado) => { 
      if(resultado.data.success)
      dispatch({ type: EDITAR_USUARIO, payload: resultado.data.data });
      swal("Editar Usuario", "Usuario actualizado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
    })
    .catch((error) => {
      if (error) {
        swal("Editar Usuario", "El usuario no pudo ser actualizado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};

export const updateUsuario = (usuario) => async (dispatch) => {
  let urlv2 = api_basev2 + "Usuarios/ActualizarUsuario";
  let url = api_base + `api/v1/usuarios/editar`;
  dispatch({ type: LOADING_USUARIOS });
  await getData
    .post(urlv2, usuario)
    .then((resultado) => {
      dispatch({ type: EDITAR_USUARIO, payload: resultado.data });
       swal("Editar Usuario", "Usuario actualizado","success").then((value)=>{
        dispatch({ type: USUARIOS_SUCCEEDED });
      });
    })
    .catch((error) => {
      if (error) {
        swal("Editar Usuario", "El usuario no pudo ser actualizado","error").then((value)=>{
          dispatch({ type: USUARIOS_FAILED });
        });
      }
    });
};
