import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateActividades,
  getActividad,
  deletePresupuesto,
  addPresupuesto,
} from "../../redux/actions/actividades-actions";
import { ModalInsumos } from "./../utils/ModalInsumos";
import { ModalInsumosEditar } from "./../utils/ModalInsumosEdit";
import { getMediosVerificacion } from "./../../redux/actions/medios-verificacion-actions";
import { getUnidadesMedida } from "./../../redux/actions/unidades-medida-actions";
import Loading from "../utils/Loading";
import UnidadMedida from "./../unidades-medida/UnidadMedidas";
import { Authorization } from "./../../utils/Authorization";
import {
  getMaestroInsumos,
  getMaestroOperaciones,
  agregarInsumosTemporales,
  limpiarInsumosTemporales
} from "./../../redux/actions/maestro-Insumos-actions";
import ListaInsumos from "./ListaInsumos.jsx";
import { Container } from "reactstrap";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

class EditarActividad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      actividad: {},
      modalInsumos: false,
      modalInsumosEditar: false,
      isLoadingInsumos: false,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
      insumos: [],
      insumoEditar: {},
      actividadEdit: true,
      mediosVerificacionEdit: true,
      primerTrimestreEdit: true,
      segundoTrimestreEdit: true,
      tercerTrimestreEdit: true,
      cuartoTrimestreEdit: true,
      ponderacionActividadEdit: true,
      validarTotalPonderacion: false,
      unidadMedidaInsumo: "",
      mesesHabilesCalendar: [],
      mostrarTodos: false,
      medioVerificacionDefault: {}
    };
    // let medioVerificacionDefault = {}
  }
  


  actividadRef = React.createRef();
  medioVerificacionRef = React.createRef();
  ponderacionActividadRef = React.createRef();
  unidadMedidaRef = React.createRef();
  primerTrimestreRef = React.createRef();
  segundoTrimestreRef = React.createRef();
  tercerTrimestreRef = React.createRef();
  cuartoTrimestreRef = React.createRef();

  componentDidMount() {
    const { actividadId } = this.props.match.params;
    this.props.limpiarInsumosTemporales();
    //  console.log("Limpio Limpio Limpio Limpio ")
    this.cargarData(actividadId).then(
      () =>
        (this.unidadMedidaRef.current.value = this.props.actividad.unidadMedida)
    );
    this.setState({medioVerificacionDefault: {}})
    this.validarInput();
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { isLoading, actividad } = nextProps;
    this.setState(
      {
        actividad,
        insumos: actividad.presupuesto,
      },
      () => this.calcularPonderacionTotalProductos()
    );
  }

  editarActividad = (e) => {
    e.preventDefault();
    if (
      this.validarActividad(
        this.actividadRef.current.value,
        this.medioVerificacionRef,
        this.ponderacionActividadRef.current.value,
        this.unidadMedidaRef.current.value,
        this.primerTrimestreRef.current.value,
        this.segundoTrimestreRef.current.value,
        this.tercerTrimestreRef.current.value,
        this.cuartoTrimestreRef.current.value
      )
    ) {
      let actividad = {
        descripcion: this.actividadRef.current.value,
        medioVerificacionId: this.medioVerificacionRef.medioVerificacionId,
        ponderacionActividad: this.ponderacionActividadRef.current.value,
        unidadMedida: this.unidadMedidaRef.current.value,
        t1: this.primerTrimestreRef.current.value,
        t2: this.segundoTrimestreRef.current.value,
        t3: this.tercerTrimestreRef.current.value,
        t4: this.cuartoTrimestreRef.current.value,
        productoId: this.props.producto.productoId,
        actividadId: this.props.actividad.actividadId,
        // insumos: this.state.insumos,
        presupuesto: this.props.maestroInsumos.insumosTemporales,
        EmailUsuario: this.props.user.email
      };
      this.medioVerificacionRef.medioVerificacionId = null;
      this.props
        .updateActividades(actividad)
        .then((data) => {
          this.props.history.push(
            `/admin/producto/detalle/${this.props.producto.productoId}`
          );
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  cargarData = async (actividadId) => {
    await this.props.getActividad(actividadId);
    await this.props.getMediosVerificacion();
    await this.props.getUnidadesMedida("actividades");
    await this.props.agregarInsumosTemporales(this.props.actividad.presupuesto);
    this.setState(
      {
        // insumos: this.props.actividad.presupuesto,
        isLoading: false
      },
      () => {
        this.ActivarModoEditarActividades();
        this.validarMesesCompraInsumos();
      }
    );
  };

  validarMesesCompraInsumos = () => {
    const objectRef = {
      t1: "primerTrimestreRef",
      t2: "segundoTrimestreRef",
      t3: "tercerTrimestreRef",
      t4: "cuartoTrimestreRef",
    }
    let mesesHabiles = []

    for (let index = 1; index <= 4; index++) {
      if (this.props.actividad[`t${index}`] > 0 || (this[`${objectRef[`t${index}`]}`].current && this[`${objectRef[`t${index}`]}`].current.value > 0)) {
        mesesHabiles = [...mesesHabiles, `t${index}`]
      }
    }
    this.setState({ mesesHabilesCalendar: mesesHabiles })

  }

  validarInput = () => {
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  };

  editarProducto = (e) => {
    e.preventDefault();
    const { productoId } = this.props.match.params;

    let producto = {
      productoId: productoId,
      descripcion: this.productoRef.current.value,
      resultado: this.resultadoRef.current.value,
      indicador: this.indicadorRef.current.value,
      meta: this.metaRef.current.value,
      ponderacionArea: this.ponderacionAreaRef.current.value,
      unidadMedida: this.unidadMedidaRef.current.value,
      ejeEstrategico:
        this.ejeEstrategicoRef.current.value !== "--Seleccione una opción--"
          ? JSON.parse(this.ejeEstrategicoRef.current.value).ejeEstrategicoId
          : null,
      objetivoEstrategico:
        this.objetivoGeneralRef.current.value !== "--Seleccione una opción--"
          ? JSON.parse(this.objetivoGeneralRef.current.value)
            .objetivoEstrategicoId
          : null,
      estrategia:
        this.estrategiaRef.current.value !== "--Seleccione una opción--"
          ? JSON.parse(this.estrategiaRef.current.value).estrategiaId
          : null,
      unidadResponsables: this.unidadResponsableRef,
      involucrados: this.involucradosRef,
      // recursosExterno: this.cooperacionRef.current.value,
      poaId: 1,
      usuarioId: 2,
      ponderacionGeneral: this.ponderacionGeneralRef.current.value,
    };

    this.props
      .updateActividades(producto)
      .then((data) => {
        this.limpiarFiltros();
        this.props.history.push("/admin/productos/index");
      })
      .catch((error) => { });
  };

  validarActividad = (
    actividad,
    mediosVerificacion,
    ponderacionActividad,
    unidadMedida,
    t1,
    t2,
    t3,
    t4
  ) => {
    if (actividad === "") {
      swal(
        "Editar actividades",
        "El campo actividad es un valor requerido",
        "error"
      );
      return false;
    }
    if (this.medioVerificacionRef === undefined) {
      swal(
        "Editar actividades",
        "El campo medio de verificación es un valor requerido",
        "error"
      );
      return false;
    }
    if (ponderacionActividad === "") {
      swal(
        "Editar actividades",
        "El campo ponderación de la actividad es un valor requerido",
        "error"
      );
      return false;
    }
    if (unidadMedida === "--Seleccione una opción--") {
      swal(
        "Editar actividades",
        "El campo unidad de medida es un valor requerido",
        "error"
      );
      return false;
    }
    if (t1 === "" && t2 === "" && t3 === "" && t4 === "") {
      swal(
        "Editar actividades",
        "Debe especificar la distribución de las actividades en los diferentes trimestres",
        "error"
      );
      return false;
    }
    if (unidadMedida !== "Cantidad") {
      let total =
        (t1 !== "" ? parseFloat(t1) : 0) +
        (t2 !== "" ? parseFloat(t2) : 0) +
        (t3 !== "" ? parseFloat(t3) : 0) +
        (t4 !== "" ? parseFloat(t4) : 0);
      if (total !== 100) {
        swal(
          "Editar actividades",
          "El total de los porcientos en los trimestres debe ser 100% actualmente suman: " +
          total +
          "%",
          "error"
        );
        return false;
      }
    }
    return true;
  };

  setStateUnidadMedidaInsumo = (unidadMedida) => {
    this.setState({ unidadMedidaInsumo: unidadMedida });
  };
  consultarInsumosActividad = (
    cuenta = this.state.cuentaFiltro,
    insumo = this.state.insumoFiltro,
    operacion = this.state.operacionFiltro
  ) => {
    this.setState(
      {
        cuentaFiltro: cuenta,
        insumoFiltro: insumo,
        operacionFiltro: operacion,
        isLoadingInsumos: true,
      },
      () => {
        this.props.getMaestroInsumos(cuenta, insumo, operacion).then(() => {
          this.setState({ isLoadingInsumos: false });
        });
      }
    );
  };
  guardarInsumos = (insumo) => {
    this.setState({
      // insumos: [...this.props.maestroInsumos.insumosTemporales, insumo],
      modalInsumos: !this.state.modalInsumos,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
    }, () => {
      this.props.agregarInsumosTemporales([...this.props.maestroInsumos.insumosTemporales, insumo]);
    });
    this.limpiarFiltros();

  };

  editarInsumos = (presupuesto) => {

    let insumoTemporalesFilter = this.props.maestroInsumos.insumosTemporales.map((insumo) =>
      insumo.presupuestoId === presupuesto.presupuestoId
        ? (insumo = presupuesto)
        : insumo
    );
    this.props.agregarInsumosTemporales(insumoTemporalesFilter);
    this.setState(
      {
        // insumos: this.props.maestroInsumos.insumosTemporales.map((insumo) =>
        //   insumo.presupuestoId === presupuesto.presupuestoId
        //     ? (insumo = presupuesto)
        //     : insumo
        // ),
        modalInsumosEditar: !this.state.modalInsumosEditar,
      },
      () => {

      }
    );
  };
  ActivarModalInsumos = () => {
    this.setState({ modalInsumos: !this.state.modalInsumos });
    this.limpiarFiltros();
  };
  ActivarModalInsumosEditar = () => {
    this.setState({ modalInsumosEditar: !this.state.modalInsumosEditar });
    this.limpiarFiltros();
  };

  insumoEditar = (insumo) => {
    this.setState({
      insumoEditar: insumo,
      modalInsumosEditar: !this.state.modalInsumosEditar,
    });
  };

  eliminarPresupuesto = (e, insumo) => {
    e.preventDefault();
    let respuesta = window.confirm(
      "Está seguro de eliminar el insumo de la actividad"
    );

    if (respuesta) {
      if (insumo.presupuestoId > 0) {
        this.props.deletePresupuesto(insumo);
      } else {
        // let presupuesto = this.state.insumos.filter(
        let presupuesto = this.props.maestroInsumos.insumosTemporales.filter(
          (presupuesto) => presupuesto !== insumo
        );

        // this.setState({ insumos: presupuesto });
        this.props.agregarInsumosTemporales(presupuesto)
      }
    }
  };

  limpiarFiltros = () => {
    this.setState({
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
      unidadMedidaInsumo: "",
    });
  };

  ActivarModoEditarActividades = () => {
    let actividadEdit = true;
    let mediosVerificacionEdit = true;
    let primerTrimestreEdit = true;
    let segundoTrimestreEdit = true;
    let tercerTrimestreEdit = true;
    let cuartoTrimestreEdit = true;
    let ponderacionActividadEdit = true;
    if (this.props.producto.modoEditar) {
      let notasActividad = this.props.actividad.notasActividad;
      let indice = notasActividad.length - 1;
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].descripcion !== null
      ) {
        actividadEdit = false;
      }
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].medioVerificacion !== null
      ) {
        mediosVerificacionEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t1 !== null) {
        primerTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t2 !== null) {
        segundoTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t3 !== null) {
        tercerTrimestreEdit = false;
      }
      if (notasActividad.length > 0 && notasActividad[indice].t4 !== null) {
        cuartoTrimestreEdit = false;
      }
      if (
        notasActividad.length > 0 &&
        notasActividad[indice].ponderacionActividad !== null
      ) {
        ponderacionActividadEdit = false;
      }

      this.setState({
        actividadEdit,
        mediosVerificacionEdit,
        primerTrimestreEdit,
        segundoTrimestreEdit,
        tercerTrimestreEdit,
        cuartoTrimestreEdit,
        ponderacionActividadEdit,
      });
    } else {
      this.setState({
        actividadEdit: false,
        mediosVerificacionEdit: false,
        primerTrimestreEdit: false,
        segundoTrimestreEdit: false,
        tercerTrimestreEdit: false,
        cuartoTrimestreEdit: false,
        ponderacionActividadEdit: false,
      });
    }
  };

  calcularPonderacionTotalProductos = () => {
    if (
      this.props.totalPonderacionActividades &&
      this.props.actividad.ponderacionActividad
    ) {
      let ponderacionAreaProps =
        parseFloat(this.props.totalPonderacionActividades) - parseFloat(
          this.props.actividad.ponderacionActividad);
      let ponderacionArea =
        this.ponderacionActividadRef.current !== null &&
          this.ponderacionActividadRef.current.value !== ""
          ? this.ponderacionActividadRef.current.value
          : 0;
      let total =
        parseFloat(ponderacionAreaProps) + parseFloat(ponderacionArea);
      if (total <= 100) {
        this.setState({ validarTotalPonderacion: true });
      } else {
        this.setState({ validarTotalPonderacion: false });
        swal(
          "Editar Actividades",
          "Excede el límite de ponderación permitido para las actividades, actualmente tiene " +
          total +
          "%",
          "error"
        );
      }
    } else {
      this.setState({ validarTotalPonderacion: true });
    }
  };

  activarNota = (property) => {
    let { notasActividad } = this.props.actividad;
    let indice = notasActividad.length - 1;
    let resultado = false;
    if (
      notasActividad.length > 0 &&
      notasActividad[indice][property] !== null
    ) {
      resultado = true;
    }
    return resultado;
  };

  render() {
    const { mediosVerificacion, unidadesMedida } = this.props;
    // console.log("pros-edit",this.props)
    const {
      descripcion,
      t1,
      t2,
      t3,
      t4,
      ponderacionActividad,
      unidadMedida,
      medioVerificacion,
      medioVerificacionId,
      notasActividad,
    } = this.props.actividad;
    // console.log(Object.keys(this.state.medioVerificacionDefault).length);
    
    
    if (Object.keys(this.state.medioVerificacionDefault).length == 0) {
      // console.log(this.medioVerificacionRef);
      
      this.medioVerificacionRef = {
        medioVerificacionId: medioVerificacionId,
        descripcion: medioVerificacion,
        estado: 1
      }
      this.setState({medioVerificacionDefault: this.medioVerificacionRef})
    }
    
     
    return (
      <div className="pb-6 pt-5 pt-md-7">
        {this.state.isLoading ? (
          <Loading></Loading>
        ) : (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.editarActividad}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="actividad">Actividad</label>
                  {this.activarNota("descripcion") && (
                    <button
                      type="button"
                      data-tip={
                        notasActividad[notasActividad.length - 1].descripcion
                      }
                      data-event="click"
                      className="btn btn-sm btn-danger float-right"
                    >
                      <i className="ni ni-chat-round"></i>
                    </button>
                  )}
                  <ReactTooltip globalEventOff="click" />
                  <ReactTooltip />
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="actividad"
                    placeholder="Nombre de la actividad"
                    ref={this.actividadRef}
                    defaultValue={descripcion}
                    readOnly={this.state.actividadEdit}
                    required
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="medioVerificacion">
                    Medios de Verificación
                  </label>
                  {this.activarNota("medioVerificacion") && (
                    <button
                      type="button"
                      data-tip={
                        notasActividad[notasActividad.length - 1]
                          .medioVerificacion
                      }
                      data-event="click"
                      className="btn btn-sm btn-danger float-right"
                    >
                      <i className="ni ni-chat-round"></i>
                    </button>
                  )}

                  <Select
                    defaultValue = {{medioVerificacionId: medioVerificacionId,
                      descripcion: medioVerificacion,
                      estado: 1
                    }}
                    placeholder="Medio de verificación"
                    // data={this.medioVerificacionRef}
                    name="medioVerificacion"
                    options={!this.state.mostrarTodos ? mediosVerificacion.slice(0, 100) : mediosVerificacion}
                    // ref={this.medioVerificacionRef}
                    getOptionLabel={option => option.descripcion}
                    getOptionValue={option => option.medioVerificacionId}
                    onChange={e => {
                      this.medioVerificacionRef = e;
                      console.log(e);
                      
                      
                    }}
                    onBlur={e => {
                      e.preventDefault();
                    }}
                    onInputChange={e => {
                      if (e.length > 0) {
                        this.setState({ mostrarTodos: true })
                      }
                      else{
                        this.setState({mostrarTodos: false})
                      }

                    }}
                    
                  ></Select>

                  {/* <select
                    className="custom-select custom-select-sm text-dark"
                    id="medioVerificacion"
                    ref={this.medioVerificacionRef}
                    defaultValue={
                      medioVerificacion !== undefined
                        ? medioVerificacionId
                        : ""
                    }
                    disabled={this.state.mediosVerificacionEdit}
                  >
                    {mediosVerificacion.map((medio) => (
                      <option
                        value={medio.medioVerificacionId}
                        key={medio.medioVerificacionId}
                      >
                        {medio.descripcion}
                      </option>
                    ))}
                  </select> */}
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label htmlFor="ponderacionActividad">
                      Ponderación Actividad
                    </label>
                    {this.activarNota("ponderacionActividad") && (
                      <button
                        type="button"
                        data-tip={
                          notasActividad[notasActividad.length - 1]
                            .ponderacionActividad
                        }
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionActividad"
                        placeholder="Ponderación de Actividad"
                        ref={this.ponderacionActividadRef}
                        defaultValue={ponderacionActividad}
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.calcularPonderacionTotalProductos();
                        }}
                        readOnly={this.state.ponderacionActividadEdit}
                        required
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef",
                            borderLeft: "2px solid  #bebaba",
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label htmlFor="unidadMedida">Método de Cálculo </label>

                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="unidadMedidaRef"
                      ref={this.unidadMedidaRef}
                      defaultValue={unidadMedida}
                      disabled={this.state.ponderacionActividadEdit}
                      required
                    >
                      {unidadesMedida.map((unidadMedida) => (
                        <UnidadMedida
                          key={unidadMedida.nombre}
                          unidadMedida={unidadMedida}
                        ></UnidadMedida>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-3 ">
                    <label htmlFor="primerTrimestre">Primer Trimestre</label>
                    {this.activarNota("t1") && (
                      <button
                        type="button"
                        data-tip={notasActividad[notasActividad.length - 1].t1}
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="primerTrimestre"
                      placeholder="Primer Trimestre"
                      ref={this.primerTrimestreRef}
                      defaultValue={t1}
                      readOnly={this.state.primerTrimestreEdit}
                      onChange={() => { this.validarMesesCompraInsumos() }}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="segundoTrimestre">Segundo Trimestre</label>
                    {this.activarNota("t2") && (
                      <button
                        type="button"
                        data-tip={notasActividad[notasActividad.length - 1].t2}
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="segundoTrimestre"
                      placeholder="Segundo Trimestre"
                      ref={this.segundoTrimestreRef}
                      defaultValue={t2}
                      readOnly={this.state.segundoTrimestreEdit}
                      onChange={() => { this.validarMesesCompraInsumos() }}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="tercerTrimestre">Tercer Trimestre</label>
                    {this.activarNota("t3") && (
                      <button
                        type="button"
                        data-tip={notasActividad[notasActividad.length - 1].t3}
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="tercerTrimestre"
                      placeholder="Tercer Trimestre"
                      ref={this.tercerTrimestreRef}
                      defaultValue={t3}
                      readOnly={this.state.tercerTrimestreEdit}
                      onChange={() => { this.validarMesesCompraInsumos() }}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="cuartoTrimestre">Cuarto Trimestre</label>
                    {this.activarNota("t4") && (
                      <button
                        type="button"
                        data-tip={notasActividad[notasActividad.length - 1].t4}
                        data-event="click"
                        className="btn btn-sm btn-danger float-right"
                      >
                        <i className="ni ni-chat-round"></i>
                      </button>
                    )}
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="cuartoTrimestre"
                      placeholder="Cuarto Trimestre"
                      ref={this.cuartoTrimestreRef}
                      defaultValue={t4}
                      readOnly={this.state.cuartoTrimestreEdit}
                      onChange={() => { this.validarMesesCompraInsumos() }}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2>Insumos de la Actividad</h2>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn  btn-info float-right mb-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          modalInsumos: !this.state.modalInsumos,
                        });
                      }}
                    >
                      Agregar Insumo
                    </button>
                    <ListaInsumos
                      // insumos={this.props.maestroInsumos.insumosTemporales.length>0?this.props.maestroInsumos.insumosTemporales:this.state.insumos}
                      insumos={this.props.maestroInsumos.insumosTemporales}
                      eliminarPresupuesto={this.eliminarPresupuesto}
                      insumoEditar={this.insumoEditar}
                      ActivarModalInsumos={this.modalInsumosEditar}
                    ></ListaInsumos>
                  </div>
                </div>

                {this.state.validarTotalPonderacion ? (
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                ) : (
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="btn btn-primary opacity"
                    title="Excede el límite de ponderación permitido para las actividades"
                  >
                    Guardar
                  </button>
                )}
              </form>
            </div>
            {this.state.modalInsumos && (
              <ModalInsumos
                modalInsumos={this.state.modalInsumos}
                ActivarModalInsumos={this.ActivarModalInsumos}
                consultarInsumosActividad={this.consultarInsumosActividad}
                state={this.state}
                maestroInsumos={this.props.maestroInsumos.maestroInsumos}
                operaciones={this.props.maestroInsumos.operaciones}
                guardarInsumos={this.guardarInsumos}
                setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
              ></ModalInsumos>
            )}
            {this.state.modalInsumosEditar && (
              <ModalInsumosEditar
                modalInsumosEditar={this.state.modalInsumosEditar}
                ActivarModalInsumos={this.ActivarModalInsumosEditar}
                consultarInsumosActividad={this.consultarInsumosActividad}
                state={this.state}
                maestroInsumos={this.props.maestroInsumos.maestroInsumos}
                operaciones={this.props.maestroInsumos.operaciones}
                editarInsumos={this.editarInsumos}
                setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
              ></ModalInsumosEditar>
            )}
          </Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    actividades: state.actividades.actividades,
    actividad: state.actividades.actividad,
    isLoading: state.actividades.isLoading,
    unidadesMedida: state.unidadesMedida.unidadesMedida,
    mediosVerificacion: state.mediosVerificacion.mediosVerificacion,
    isLoadingMedio: state.mediosVerificacion.isLoading,
    isLoadingUnidadMedida: state.unidadesMedida.isLoading,
    producto: state.productos.producto,
    user: state.auth.signed,
    maestroInsumos: state.maestroInsumos,
    presupuesto: state.actividades.actividad.presupuesto,
    totalPonderacionActividades: state.actividades.totalPonderacionActividades,
  };
};
const mapDispatchToProps = () => (dispatch) => ({
  getActividad: (actividadId) => dispatch(getActividad(actividadId)),
  updateActividades: (actividades) => dispatch(updateActividades(actividades)),
  getMediosVerificacion: () => dispatch(getMediosVerificacion()),
  getUnidadesMedida: (modulo) => dispatch(getUnidadesMedida(modulo)),
  getMaestroInsumos: (cuenta, insumo, operacion) =>
    dispatch(getMaestroInsumos(cuenta, insumo, operacion)),
  getMaestroOperaciones: () => dispatch(getMaestroOperaciones()),
  deletePresupuesto: (presupuesto) => dispatch(deletePresupuesto(presupuesto)),
  addPresupuesto: (presupuesto) => dispatch(addPresupuesto(presupuesto)),
  agregarInsumosTemporales: (insumos) => dispatch(agregarInsumosTemporales(insumos)),
  limpiarInsumosTemporales: () => dispatch(limpiarInsumosTemporales())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarActividad, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Gestor del POA",
    "MAE",
  ])
);
