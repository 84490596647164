import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createMedioVerificacion,
  getMedioVerificacion
} from "./../../redux/actions/medios-verificacion-actions";
import { Authorization } from "./../../utils/Authorization";
import "react-widgets/dist/css/react-widgets.css";
import {
  Container
} from "reactstrap";
import swal from "sweetalert";
import Loading from "../utils/Loading";

class CrearMedioVerificacion extends Component {
  descripcionRef = React.createRef();
  estadoRef = React.createRef();
  
  
  crearMedioVerificacion = e => {
    
    e.preventDefault();
    if (
      this.descripcionRef.current !== null &&
      this.descripcionRef.current.value !== ""
    ) {
      let nuevoMedioVerificacion = {
        descripcion: this.descripcionRef.current.value,
        estado: this.estadoRef.current.value ? 1 : 0,
        creadoPor: this.props.user.id
      };

      if (nuevoMedioVerificacion) {
        this.props
          .createMedioVerificacion(nuevoMedioVerificacion)
          .then(resultado => this.props.history.goBack());
      } else {
        swal("Medios Verificación","Debe ingresar los valores a crear","error");
        
      }
    } else {
      swal("Medios Verificación","El medio de verificación es un valor requerido","error");
    }
  };
  render() {
    const { isLoading } = this.props;
    return (
      <>
        {!isLoading ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container>
              <div className="container bg-white border">
                <form className="p-3" onSubmit={this.crearMedioVerificacion}>
                  <div className="form-group">
                    <label htmlFor="descripción">Medio de Verificación</label>
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="descripción"
                      placeholder="Medio de Verificación"
                      ref={this.descripcionRef}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    <select
                      className="form-control text-dark"
                      id="estado"
                      ref={this.estadoRef}
                    >
                      <option defaultValue={true} value={true}>
                        Activo
                      </option>
                      <option value={false}>Inactivo</option>
                    </select>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                </form>
              </div>
            </Container>
          </div>
        ) : (
          <div className="pb-6 pt-5 pt-md-7">
            <Loading></Loading>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    mediosVerificacion: state.mediosVerificacion.mediosVerificacion,
    isLoading: state.mediosVerificacion.isLoading,
    user: state.auth.signed
  };
};

const mapDispatchToProps = dispatch => ({
  createMedioVerificacion: involucrado =>
    dispatch(createMedioVerificacion(involucrado)),
  getMedioVerificacion: () => dispatch(getMedioVerificacion())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(CrearMedioVerificacion, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "MAE"
  ])
);
