import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mostrarPoas } from "./../../redux/actions/poa-actions";
import Loading from "./../utils/Loading.jsx";
import { Authorization } from "./../../utils/Authorization";
import swal from "sweetalert";

import {
  Table,
  Container
} from "reactstrap";

import Poa from "./poa";

class ListaPoa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState:true,
      opcionAdmin:false,
      botonActivo: true
    };
  }

  cargarDatos= async ()=> {
    this.setState({ isLoadingState:true });
    await this.props.mostrarPoas();

    for (let index = 0; index < this.props.user.roles.length; index++) {
      if("Administrador general" === this.props.user.roles[index] || "Gestor del POA" === this.props.user.roles[index] || "Analista del POA" === this.props.user.roles[index] ){
        this.setState({opcionAdmin: true})
        break;
      }
    }
    
    this.setState({ isLoadingState:false });
  }

  setLoading=()=> {
    this.setState({ isLoadingState: !this.state.isLoadingState });
  }

  validarPoaActivo(){
    let resultado = this.props.poas.filter(
      (poa) => poa.nivelAprobacionId === 1
    );

    if (Object.keys(resultado).length > 0) {
      this.setState({
        botonActivo: false,
      });
    }
    else {
      this.setState({
        botonActivo: true,
      });
    }


  }

  botonPoaActivo = () =>{
    if (!this.state.botonActivo) {
      swal("Agregar nuevo POA",
           "No es posible registrar un nuevo POA, ya que existe un POA pendiente de aprobación.", 
           "warning");
    }
  }

  componentDidMount() {
    this.cargarDatos();
    this.validarPoaActivo();
  }



  render() {
    const { poas } = this.props;
     const { isLoadingState } = this.state;
    return (
      <>
        {poas && poas.length > 0 && !isLoadingState ? (
          <div className="pb-6 pt-5 pt-md-7">
            <Container fluid>
              <div className="container bg-white border py-3">
                <div className="header-body">

                  {this.state.botonActivo ? (
                  <Link
                    className="btn btn-lg btn-info float-right mb-3"
                    to="/admin/poa/nuevo"
                  >
                    Agregar nuevo POA
                  </Link>
                  )
                  :
                  <button
                    className="btn btn-lg btn-info float-right mb-3"
                    // disabled
                    onClick={this.botonPoaActivo}
                  >
                    Agregar nuevo POA
                  </button>
                  }

                  <Table
                    style={{ backgroundColor: "#fff", color: "black" }}
                    responsive
                    className="table-striped"
                  >
                    <thead>
                      <tr className="text-left">
                        <th>#</th>
                        <th>Año</th>
                        <th>Nombre</th>
                        <th>Fecha Cierre</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {poas.map((poa) => (
                        <Poa key={poa.poaId} poa={poa} poas={poas} opcionAdmin={this.state.opcionAdmin} setLoading={this.setLoading} />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </>
    );
  }
}
// state
const mapStateToProps = (state) => ({
  poas: state.poas.poas,
  user: state.auth.signed,
});

const mapDispatchToProps = (dispatch) => ({
  mostrarPoas: () => dispatch(mostrarPoas()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps //{ Login }
)(
  Authorization(ListaPoa, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
