import {
  MOSTRAR_ROLES,
  LOADING_ROLES,
  ROLES_SUCCEEDED,
  ROLES_FAILD,
} from "./../types";
import { api_base, api_basev2 } from "./../../config";
import { getData } from "./../../utils/api-client";
export const getRoles = () => async (dispatch) => {
  let url = api_base + `api/v1/roles`;
  let urlv2 = api_basev2 + `Roles/GetAll`
  dispatch({ type: LOADING_ROLES });
  await getData
    .get(urlv2)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ROLES, payload: resultado.data.data });
      dispatch({ type: ROLES_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({ type: ROLES_FAILD });
      }
    });
};
