import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "./../../utils/Loading.jsx";
import AdminListaActividades from "./../../administracion/actividades/listaActividades.jsx";
import {
  getProducto,
  updateProducto,
  calcularPonderacionArea
} from "./../../../redux/actions/productos-actions";
import { ModalProducto } from "./../../utils/ModalProducto.jsx";
import { ModalActividad } from "./../../utils/ModalActividad.jsx";
import { Authorization } from "./../../../utils/Authorization";
import { createNotaProducto } from "./../../../redux/actions/nota-producto-actions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from "reactstrap";
import swal from "sweetalert";
class AdminProducto extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      producto: {},
      isLoading: true,
      notaActividades: [],
      notaProducto: {}
    };
  }
  componentDidMount() {
    const { productoId } = this.props.match.params;

    this.props.getProducto(productoId);
  }

  activarModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { producto } = nextProps;
    this.setState({
      producto: producto,
      isLoading: false,
      modal: false,
      notaProducto: {},
      notaActividades: []
    });

  }

  dispatchProductos = productoId => async dispatch => {
    dispatch(getProducto(productoId));
  };

  EliminarNotaActividad = notaActividades => {
    this.setState(
      { notaActividades });
  };

  agregarNotaActividad = notaActividad => {
    let notaActividades = this.state.notaActividades;
    let encontrado = false;
    if (this.state.notaActividades.length > 0) {
      for (let i = 0; i < notaActividades.length; i++) {
        if (
          notaActividades[i].actividad.actividadId ===
          notaActividad.actividad.actividadId
        ) {
          notaActividades[i] = notaActividad;
          encontrado = true;
          break;
        }
      }
      if (!encontrado) {
        this.setState({
          notaActividades: [...notaActividades, notaActividad]
        });
      } else {
        this.setState({
          notaActividades: notaActividades
        });
      }
    } else {
      this.setState(
        {
          notaActividades: [...this.state.notaActividades, notaActividad]
        }
      );
    }
  };
  agregarNota = notaProducto => {

    this.setState(
      {
        notaProducto: notaProducto
      }
    );
  };

  aprobarProducto = producto =>  {

    swal({
      title: "Está seguro de aprobar este producto?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        let productoEditar = {
          productoId: producto.productoId,
          descripcion: producto.descripcion,
          resultado: producto.resultado,
          indicador: producto.indicador,
          meta: producto.meta,
          ponderacionArea: producto.ponderacionArea,
          ejeEstrategico: producto.ejeEstrategico.ejeEstrategicoId,
          objetivoEstrategico: producto.objetivoEstrategico.objetivoEstrategicoId,
  
          estrategia: producto.estrategia.estrategiaId,
  
          unidadResponsables: producto.unidadResponsables,
          involucrados: producto.involucradosProductos.involucradoId,
          recursosExterno: producto.recursosExterno,
          poaId: this.props.poaActivo.poaId,
  
          ponderacionGeneral: producto.ponderacionGeneral,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          accionMitigacion: producto.accionMitigacion,
          riesgoAsociado:producto.riesgoAsociado,
          modoEditar: true,
          email: this.props.user.email
        };
        this.props.updateProducto(productoEditar);
        this.props.history.goBack();
      }
    });
  };

  devolverProducto = async producto => {

    swal({
      title: "Está seguro de devolver el producto?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let productoEditar = {
          productoId: producto.productoId,
          descripcion: producto.descripcion,
          resultado: producto.resultado,
          indicador: producto.indicador,
          meta: producto.meta,
          ponderacionArea: producto.ponderacionArea,
          ejeEstrategico: producto.ejeEstrategico.ejeEstrategicoId,
          objetivoEstrategico: producto.objetivoEstrategico.objetivoEstrategicoId,
  
          estrategia: producto.estrategia.estrategiaId,
  
          unidadResponsables: producto.unidadResponsables,
          involucrados: producto.involucradosProductos.involucradoId,
          recursosExterno: producto.recursosExterno,
          poaId: this.props.poaActivo.poaId,
  
          ponderacionGeneral: producto.ponderacionGeneral,
          nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
          accionMitigacion: producto.accionMitigacion,
          riesgoAsociado:producto.riesgoAsociado,
          modoEditar: true,
          email: this.props.user.email
        };
        let notaProduto = {
          producto: producto,
          productoId: producto.productoId,
          descripcion: this.state.notaProducto.descripcion,
          indicador: this.state.notaProducto.indicador,
          ponderacionArea: this.state.notaProducto.ponderacionArea,
          unidadResponsable: this.state.notaProducto.unidadResponsable,
          comentario: this.state.notaProducto.comentario,
          meta: this.state.notaProducto.meta,
          actividades: this.state.notaActividades,
          resultado: this.state.notaProducto.resultado,
          involucrados:this.state.notaProducto.involucrados,
          cooperacion:this.state.notaProducto.cooperacion,
          accionMitigacion:this.state.notaProducto.accionMitigacion,
          riesgoAsociado:this.state.notaProducto.riesgoAsociado
        };
  
        await this.props.updateProducto(productoEditar);
        await this.props.createNotaProducto(notaProduto);
        this.props.history.goBack();
      }
    });
  };

  render() {
    const { producto, isLoading } = this.props;

    const {
      productoId,
      descripcion,
      resultado,
      meta,
      indicador,
      ponderacionArea
    } = producto;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid >
        <div className="container bg-white border py-3">
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearProducto}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="descripción">Producto</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="descripción"
                    placeholder="Nombre del producto"
                    value={descripcion}
                    readOnly
                  />
                </div>
                <div className="form-group ">
                  <label htmlFor="descripción">Resultado</label>
                  <textarea
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="resultado"
                    placeholder="Resultado del producto"
                    value={resultado}
                    readOnly
                  />
                </div>

                <div className="row">
                  <div className="form-group col-md-2">
                    <label htmlFor="meta">Meta</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="meta"
                      placeholder="Meta"
                      value={meta}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="indicador">Indicador</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="indicador"
                      placeholder="Indicador del producto"
                      value={indicador}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="ponderacionArea">
                      Ponderación del Producto en la Unidad
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionArea"
                        placeholder="Ponderación del Producto en la Unidad"
                        value={ponderacionArea}
                        readOnly
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef",
                            borderLeft: "2px solid  #bebaba"
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-info"
                      onClick={e => {
                        e.preventDefault();
                        this.activarModal();
                      }}
                    >
                      Agregar Nota Producto
                    </button>
                  </div>
                </div>

                <AdminListaActividades
                  productoId={productoId}
                  agregarNotaActividad={this.agregarNotaActividad}
                  notaActividades={this.state.notaActividades}
                  EliminarNotaActividad={this.EliminarNotaActividad}
                ></AdminListaActividades>

                <ModalProducto
                  modal={this.state.modal}
                  activarModal={this.activarModal}
                  notaProducto={this.state.notaProducto}
                  agregarNota={this.agregarNota}
                ></ModalProducto>
                {this.state.notaActividades.length === 0 &&
                Object.keys(this.state.notaProducto).length === 0 ? (
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.aprobarProducto(producto);
                    }}
                    className="btn btn-primary"
                  >
                    Aprobar
                  </button>
                ) : (
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.devolverProducto(producto);
                    }}
                    className="btn btn-danger"
                  >
                    Devolver
                  </button>
                )}
              </form>
            </div>
          )}
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productos: state.productos.productos,
  producto: state.productos.producto,
  isLoading: state.productos.isLoading,
  user: state.auth.signed,
  poaActivo: state.poas.poaActivo,
  totalPonderacionProductos: state.productos.totalPonderacionProductos
});

const mapDispatchToProps = dispatch => ({
  getProducto: productoId => dispatch(getProducto(productoId)),
  updateProducto: producto => dispatch(updateProducto(producto)),
  createNotaProducto: notaProducto =>
    dispatch(createNotaProducto(notaProducto)),
  calcularPonderacionArea: (productos, user) =>
    dispatch(calcularPonderacionArea(productos, user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(AdminProducto, [
    "Administrador general",
    "Analista del POA",
    "Gestor del POA",
    "Responsables de areas",
    "MAE"
  ])
);
