import React, { Component } from "react";

import { connect } from "react-redux";
import {
  Container,
} from "reactstrap";
import swal from "sweetalert";
import { Authorization } from "./../../utils/Authorization";

import ListaInsumos from "./ListaInsumos.jsx";

import Select from "react-select";

import { createActividades } from "./../../redux/actions/actividades-actions";
import { getMediosVerificacion } from "./../../redux/actions/medios-verificacion-actions";
import { getUnidadesMedida } from "./../../redux/actions/unidades-medida-actions";
import Loading from "../utils/Loading";
import UnidadMedida from "./../unidades-medida/UnidadMedidas";
import { ModalInsumos } from "./../utils/ModalInsumos.jsx";
import {ModalInsumosEditar} from  "./../utils/ModalInsumosEdit.jsx"
import {
  getMaestroInsumos,
  getMaestroOperaciones,
  agregarInsumosTemporales,
  limpiarInsumosTemporales
} from "./../../redux/actions/maestro-Insumos-actions";

class CrearActividades extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalInsumos: false,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null",
      isLoading: false,
      insumos: [],
      validarTotalPonderacion: false,
      isLoadingInsumos: false,
      unidadMedidaInsumo: "",
      modalInsumosEditar:false,
      insumoEditar:{},
      mostrarTodos: false
    };
  }

  actividadRef = React.createRef();
  medioVerificacionRef = React.createRef();
  ponderacionActividadRef = React.createRef();
  unidadMedidaRef = React.createRef();
  primerTrimestreRef = React.createRef();
  segundoTrimestreRef = React.createRef();
  tercerTrimestreRef = React.createRef();
  cuartoTrimestreRef = React.createRef();

  crearActividad = async e => {
    e.preventDefault();

    if (
      this.validarActividad(
        this.actividadRef.current.value,
        this.medioVerificacionRef.current.value,
        this.ponderacionActividadRef.current.value,
        this.unidadMedidaRef.current.value,
        this.primerTrimestreRef.current.value,
        this.segundoTrimestreRef.current.value,
        this.tercerTrimestreRef.current.value,
        this.cuartoTrimestreRef.current.value
      )
    ) {
      let mediosVerificacion = this.medioVerificacionRef.current.value
      
      let actividad = {
        descripcion: this.actividadRef.current.value,
        medioVerificacion: mediosVerificacion.descripcion,
        medioVerificacionId: mediosVerificacion.medioVerificacionId,
        ponderacionActividad: this.ponderacionActividadRef.current.value,
        unidadMedida: this.unidadMedidaRef.current.value,
        t1: this.primerTrimestreRef.current.value !== "" ? this.primerTrimestreRef.current.value : "0" ,
        t2: this.segundoTrimestreRef.current.value !== "" ? this.segundoTrimestreRef.current.value : "0",
        t3: this.tercerTrimestreRef.current.value !== "" ? this.tercerTrimestreRef.current.value : "0",
        t4: this.cuartoTrimestreRef.current.value !== "" ? this.cuartoTrimestreRef.current.value : "0",
        productoId: this.props.producto.productoId,
        EmailUsuario: this.props.user.email,
        // insumos: this.state.insumos
        presupuesto: this.props.maestroInsumos.insumosTemporales
      };
      // //console.log(actividad);
      
      await this.props.createActividades(actividad);
      // dispatch({ type: LOADING_ACTIVIDAD });
      // setTimeout(() => {
      //   dispatch({ type: ACTIVIDAD_SUCCEEDED });

      this.props.history.goBack();
      // }, 3000);
    }
  };
  validarInput = () => {
    window.addEventListener(
      "load",
      function () {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName("needs-validation");
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function (form) {
          form.addEventListener(
            "submit",
            function (event) {
              if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
              }
              form.classList.add("was-validated");
            },
            false
          );
        });
      },
      false
    );
  };

  validarActividad = (
    actividad,
    mediosVerificacion,
    ponderacionActividad,
    unidadMedida,
    t1,
    t2,
    t3,
    t4
  ) => {
    if (actividad === "") {
      swal("Crear Actividad", "El campo actividad es un valor requerido", "error");
      return false;
    }
    if (mediosVerificacion === "--Seleccione una opción--") {
      swal("Crear Actividad", "El campo medio de verificación es un valor requerido", "error");
      return false;
    }
    if (ponderacionActividad === "") {
      swal("Crear Actividad", "El campo ponderación de la actividad es un valor requerido", "error");
      return false;
    }
    if (unidadMedida === "--Seleccione una opción--") {
      swal("Crear Actividad", "El campo unidad de medida es un valor requerido", "error");
      return false;
    }
    if (t1 === "" && t2 === "" && t3 === "" && t4 === "") {
      swal("Crear Actividad", "Debe especificar la distribución de las actividades en los diferentes trimestres", "error");
      return false;
    }
    if (unidadMedida !== "Cantidad") {
      let total =
        (t1 !== "" ? parseFloat(t1) : 0) +
        (t2 !== "" ? parseFloat(t2) : 0) +
        (t3 !== "" ? parseFloat(t3) : 0) +
        (t4 !== "" ? parseFloat(t4) : 0);
      if (total !== 100) {
        swal("Crear Actividad", "El total de los porcientos en los trimestres debe ser 100% actualmente suman: " +
          total + "%", "error");

        return false;
      }
    }
    return true;
  };

  validarPorcentajeTrimestres = () => {
    if (this.unidadMedidaRef.current.value === "Porcentaje de avance") {
      let total =
        (this.primerTrimestreRef.current.value !== ""
          ? parseFloat(this.primerTrimestreRef.current.value)
          : 0) +
        (this.segundoTrimestreRef.current.value !== ""
          ? parseFloat(this.segundoTrimestreRef.current.value)
          : 0) +
        (this.tercerTrimestreRef.current.value !== ""
          ? parseFloat(this.tercerTrimestreRef.current.value)
          : 0) +
        (this.cuartoTrimestreRef.current.value !== ""
          ? parseFloat(this.cuartoTrimestreRef.current.value)
          : 0);
      if (total > 100) {
        swal("Crear Actividad", "El total de los porcientos en los trimestres debe ser 100% actualmente suman: " +
          total + "%", "error");

        return false;
      }

    }
    this.validarMesesCompraInsumos()
  };

  validarMesesCompraInsumos = () => {
    const objectRef = {
      t1: "primerTrimestreRef",
      t2: "segundoTrimestreRef",
      t3: "tercerTrimestreRef",
      t4: "cuartoTrimestreRef",
    }
    let mesesHabiles = []

    for (let index = 1; index <= 4; index++) {
      if ((this[`${objectRef[`t${index}`]}`].current && this[`${objectRef[`t${index}`]}`].current.value > 0)) {
        mesesHabiles = [...mesesHabiles, `t${index}`]
      }
    }
    this.setState({ mesesHabilesCalendar: mesesHabiles })

  }

  componentDidMount() {
    this.props.limpiarInsumosTemporales();
    this.consultar();
    this.validarInput();
    this.calcularPonderacionTotalProductos();

  }

  consultar = async () => {
    this.props.getMediosVerificacion();
    this.props.getUnidadesMedida("actividades");
    this.props.getMaestroOperaciones();
    this.consultarInsumosActividad();
  };

  consultarInsumosActividad = (
    cuenta = this.state.cuentaFiltro,
    insumo = this.state.insumoFiltro,
    operacion = this.state.operacionFiltro
  ) => {
    this.setState(
      {
        cuentaFiltro: cuenta,
        insumoFiltro: insumo,
        operacionFiltro: operacion,
        isLoadingInsumos: true
      },
      () => {
        this.props
          .getMaestroInsumos(
            this.state.cuentaFiltro,
            this.state.insumoFiltro,
            this.state.operacionFiltro
          )
          .then(() => {
            this.setState({ isLoadingInsumos: false });
          });
      }
    );
  };

  guardarInsumos = insumo => {
    this.setState({
      // insumos: [...this.state.insumos, insumo],
      modalInsumos: !this.state.modalInsumos,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null"
    });
    this.props.agregarInsumosTemporales([...this.props.maestroInsumos.insumosTemporales, insumo]);
  };
  editarInsumos = insumo =>{
    this.setState({
      // insumos: [...this.state.insumos, insumo],
      modalInsumosEditar: !this.state.modalInsumosEditar,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null"
    });
    let newInsumos = this.props.maestroInsumos.insumosTemporales.filter(x=> x.insumoId!= insumo.insumoId);
    this.props.agregarInsumosTemporales([...newInsumos,insumo]);
  }
  ActivarModalInsumos = () => {
    this.setState({
      modalInsumos: !this.state.modalInsumos,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null"
    });
  };
  ActivarModalInsumosEditar = () => {
    this.setState({ modalInsumosEditar: !this.state.modalInsumosEditar,
      cuentaFiltro: "null",
      insumoFiltro: "null",
      operacionFiltro: "null" });
    
  };

  calcularPonderacionTotalProductos = () => {
    let ponderacionArea =
      this.ponderacionActividadRef.current !== null &&
        this.ponderacionActividadRef.current.value !== ""
        ? this.ponderacionActividadRef.current.value
        : 0;
    let total =
    parseFloat(this.props.totalPonderacionActividades) + parseFloat(ponderacionArea);


    if (total <= 100) {
      this.setState({ validarTotalPonderacion: true });
    } else {
      this.setState({ validarTotalPonderacion: false });
      swal("Crear Actividades", "Excede el límite de ponderación permitido para las actividades, actualmente tiene " + total + "%", "error");
    }
  };

  setStateUnidadMedidaInsumo = unidadMedida => {
    this.setState({ unidadMedidaInsumo: unidadMedida });
  };

  insumoEditar = (insumo) => {
    this.setState({
      insumoEditar: insumo,
      modalInsumosEditar: !this.state.modalInsumosEditar,
    });
  };
  eliminarPresupuesto = (e, insumo) => {
    e.preventDefault();
    let respuesta = window.confirm(
      "Está seguro de eliminar el insumo de la actividad"
    );

    if (respuesta) {
      if (insumo.presupuestoId > 0) {
        // this.props.deletePresupuesto(insumo);
      } else {
        // let presupuesto = this.state.insumos.filter(
        let presupuesto = this.props.maestroInsumos.insumosTemporales.filter(
          (presupuesto) => presupuesto !== insumo
        );

        // this.setState({ insumos: presupuesto });
        this.props.agregarInsumosTemporales(presupuesto)
      }
    }
  };


  render() {
    // console.log(this.props)
    const {
      isLoading,
      mediosVerificacion,
      unidadesMedida,
      isLoadingMedio,
      isLoadingUnidadMedida
    } = this.props;

    return (
      <div className="pb-6 pt-5 pt-md-7">
        {!isLoadingMedio && !isLoadingUnidadMedida ? (
          <Container>
            <div className="container bg-white border">
              <form
                className="needs-validation p-3 "
                onSubmit={this.crearActividad}
                noValidate
              >
                <div className="form-group ">
                  <label htmlFor="actividad">Actividad</label>
                  <input
                    type="text"
                    className="form-control form-control-sm text-dark"
                    id="actividad"
                    placeholder="Nombre de la actividad"
                    ref={this.actividadRef}
                    required
                  />
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="medioVerificacion">
                    Medios de Verificación
                  </label>

                  <Select
              placeholder="Medio de verificación"
              // data={this.medioVerificacionRef}
              name="insumoFiltro"
              options={!this.state.mostrarTodos ? mediosVerificacion.slice(0,100) : mediosVerificacion}
              ref={this.medioVerificacionRef}
              getOptionLabel={option => option.descripcion}
              getOptionValue={option => option.medioVerificacionId}
              onChange={e => {
                this.medioVerificacionRef.current.value = e;
                
              }}
              onBlur={e => {
                e.preventDefault();
              }}
              onInputChange={e => {
                if (e.length > 0) {
                  this.setState({ mostrarTodos: true})
                }
                else{
                  this.setState({mostrarTodos: false})
                }
                
              }}
            ></Select>

                  {/* <select
                    className="custom-select custom-select-sm text-dark"
                    id="medioVerificacion"
                    ref={this.medioVerificacionRef}
                  >
                    <option defaultValue>--Seleccione una opción--</option>
                    {mediosVerificacion.map(medio => (
                      <option
                        key={JSON.stringify(medio)}
                        value={JSON.stringify(medio)}
                      >
                        {medio.descripcion}
                      </option>
                    ))}
                  </select> */}
                  <div className="invalid-feedback">
                    Este es un campo obligatorio
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-12 ">
                    <label htmlFor="ponderacionActividad">
                      Ponderación de Actividad
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control form-control-sm text-dark"
                        id="ponderacionActividad"
                        placeholder="Ponderación de Actividad"
                        onKeyUp={e => {
                          e.preventDefault();

                          this.calcularPonderacionTotalProductos();
                        }}
                        ref={this.ponderacionActividadRef}
                        required
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text text-dark form-control-sm "
                          style={{
                            backgroundColor: "#e9ecef"
                          }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="unidadMedida">Unidad de Medida</label>
                    <select
                      className="custom-select custom-select-sm text-dark"
                      id="unidadMedidaRef"
                      ref={this.unidadMedidaRef}
                      required
                    >
                      <option defaultValue>--Seleccione una opción--</option>
                      {unidadesMedida.map(unidadmedida => (
                        <UnidadMedida
                          key={unidadmedida.unidadMedidaId}
                          unidadMedida={unidadmedida}
                        ></UnidadMedida>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-3 ">
                    <label htmlFor="primerTrimestre">Primer Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="primerTrimestre"
                      placeholder="Primer Trimestre"
                      ref={this.primerTrimestreRef}
                      onKeyUp={() => this.validarPorcentajeTrimestres()}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="segundoTrimestre">Segundo Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="segundoTrimestre"
                      placeholder="Segundo Trimestre"
                      ref={this.segundoTrimestreRef}
                      onKeyUp={() => this.validarPorcentajeTrimestres()}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="tercerTrimestre">Tercer Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="tercerTrimestre"
                      placeholder="Tercer Trimestre"
                      ref={this.tercerTrimestreRef}
                      onKeyUp={() => this.validarPorcentajeTrimestres()}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                  <div className="form-group col-md-3 ">
                    <label htmlFor="cuartoTrimestre">Cuarto Trimestre</label>
                    <input
                      type="text"
                      className="form-control form-control-sm text-dark"
                      id="cuartoTrimestre"
                      placeholder="Cuarto Trimestre"
                      ref={this.cuartoTrimestreRef}
                      onKeyUp={() => this.validarPorcentajeTrimestres()}
                      required
                    />
                    <div className="invalid-feedback">
                      Este es un campo obligatorio
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2>Insumos de la Actividad</h2>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn  btn-info float-right mb-3"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({
                          modalInsumos: !this.state.modalInsumos
                        });
                      }}
                    >
                      Agregar Insumo
                    </button>
                    <ListaInsumos
                      insumos={this.props.maestroInsumos.insumosTemporales}
                      insumoEditar={this.insumoEditar}
                      eliminarPresupuesto= {this.eliminarPresupuesto}
                    ></ListaInsumos>
                  </div>
                </div>
                {this.state.validarTotalPonderacion ? (
                  <button type="submit" className="btn btn-primary">
                    Guardar
                  </button>
                ) : (
                  <button
                    onClick={e => e.preventDefault()}
                    className="btn btn-primary opacity"
                    title="Excede el límite de ponderación permitido para los productos"
                  >
                    Guardar
                  </button>
                )}
              </form>
            </div>
            {this.state.modalInsumos && (
              <ModalInsumos
                modalInsumos={this.state.modalInsumos}
                ActivarModalInsumos={this.ActivarModalInsumos}
                consultarInsumosActividad={this.consultarInsumosActividad}
                state={this.state}
                maestroInsumos={this.props.maestroInsumos.maestroInsumos}
                operaciones={this.props.maestroInsumos.operaciones}
                guardarInsumos={this.guardarInsumos}
                setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
              ></ModalInsumos>
            )

            }
            {this.state.modalInsumosEditar && (
              <ModalInsumosEditar
                modalInsumosEditar={this.state.modalInsumosEditar}
                ActivarModalInsumos={this.ActivarModalInsumosEditar}
                consultarInsumosActividad={this.consultarInsumosActividad}
                state={this.state}
                maestroInsumos={this.props.maestroInsumos.maestroInsumos}
                operaciones={this.props.maestroInsumos.operaciones}
                editarInsumos={this.editarInsumos}
                setStateUnidadMedidaInsumo={this.setStateUnidadMedidaInsumo}
              ></ModalInsumosEditar>
            )}

          </Container>
        ) : (
          <Loading></Loading>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    actividades: state.actividades.actividades,
    isLoading: state.actividades.isLoading,
    unidadesMedida: state.unidadesMedida.unidadesMedida,
    mediosVerificacion: state.mediosVerificacion.mediosVerificacion,
    isLoadingMedio: state.mediosVerificacion.isLoading,
    isLoadingUnidadMedida: state.unidadesMedida.isLoading,
    producto: state.productos.producto,
    user: state.auth.signed,
    maestroInsumos: state.maestroInsumos,
    totalPonderacionActividades: state.actividades.totalPonderacionActividades,
  };
};
const mapDispatchToProps = () => dispatch => ({
  createActividades: actividades => dispatch(createActividades(actividades)),
  getMediosVerificacion: () => dispatch(getMediosVerificacion()),
  getUnidadesMedida: modulo => dispatch(getUnidadesMedida(modulo)),
  getMaestroInsumos: (cuenta, insumo, operacion) =>
    dispatch(getMaestroInsumos(cuenta, insumo, operacion)),
  getMaestroOperaciones: () => dispatch(getMaestroOperaciones()),
  agregarInsumosTemporales: (insumos) => dispatch(agregarInsumosTemporales(insumos)),
  limpiarInsumosTemporales: () => dispatch((limpiarInsumosTemporales()))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearActividades, [
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Gestor del POA",
    "MAE"
  ])
);
