import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMonitoreosActividad,
  deletMonitoreoMedioVerificacion,
  deletMonitoreoEvidencia,
  getReporteEvidenciaNoCumplimiento
} from "./../../redux/actions/monitoreo-actividad-actions";
import Monitoreo from "./../monitoreo/Monitoreo.jsx";
import { Link } from "react-router-dom";
import { Authorization } from "./../../utils/Authorization";
import swal from "sweetalert";

import { Table } from "reactstrap";
import Loading from "../utils/Loading";
import { ModalArchivos } from "./../utils/ModalArchivos";
import { ModalDevolucionMonitoreo } from "./../utils/ModalDevolucionMonitoreo";
import { updateMonitoreoActividad } from "./../../redux/actions/monitoreo-actividad-actions";

class ListaMonitoreosActividad extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalArchivos: false,
      modalDevolucion: false,
      monitoreoMediosVerificacion: [],
      justificacionDeNoCumplimiento: [],
      poaMonitoreo: [],
      isloadingState: false,
      monitoreo: {},
      statusEliminarArchivo: false,
    };
  }

  activarModalArchivos = (monitoreoMediosVerificacion, statusEliminar, justificacionDeNoCumplimiento) => {

    this.setState({
      modalArchivos: !this.state.modalArchivos,
      monitoreoMediosVerificacion,
      justificacionDeNoCumplimiento,
      statusEliminarArchivo: statusEliminar,
    });
  };

  activarModalDevolucionMonitoreo = (monitoreo) => {
    this.setState({
      modalDevolucion: !this.state.modalDevolucion,
      monitoreo,
    });
  };

  enviarMonitoreo = async (monitoreoActividad, actividadId) => {
    this.setState({
      isloadingState: true,
    });

    if (
      monitoreoActividad.monitoreoMediosVerificacion.length > 0 ||
      monitoreoActividad.nivelEjecucion == 0
    ) {
      swal({
        title: "Enviar Monitoreo",
        text: "Está seguro de enviar a revisión este monitoreo?",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: "Cancelar",
          catch: {
            text: "Aceptar",
            value: true,
          },
        },
        dangerMode: false,
        cancel: "Cancelar",
      }).then(async (result) => {
        if (result) {
          this.setState({ isLoadingState: true });
          const monitoreoActividadentity = {
            MonitoreoId: monitoreoActividad.monitoreoId,
            productoId: this.props.producto.productoId,
            actividadId: actividadId,
            nivelAprobacionId: this.props.user.nivelAprobacion.padre,
            envio: true,
            trimestre: monitoreoActividad.trimestre,
            creadoPor: this.props.user.id,
            nivelEjecucion: monitoreoActividad.nivelEjecucion
          };
          //console.log(monitoreoActividadentity);

          const fd = new FormData();
          //console.log(this.state.file);

          Object.keys(monitoreoActividadentity).forEach(function (item) {
            fd.append(item, monitoreoActividadentity[item]);
          });
          await this.props.updateMonitoreoActividad(fd);
          this.setState({ isLoadingState: false });
        }
      });
    } else {
      // swal("Debe agregar el medio de verificación, para poder ser enviado a revisión el monitoreo","error")
      swal({
        title: "Enviar Monitoreo!",
        text:
          "Debe agregar el medio de verificación, para poder ser enviado a revisión el monitoreo!",
        icon: "error",
        button: "Aceptar",
      });
      this.setState({
        isloadingState: false,
      });
    }
  };

  devolverMonitoreo = async (monitoreo, comentarioHistorial) => {
    this.setState({
      isLoadingState: true,
      modalDevolucion: !this.state.modalDevolucion,
    });

    const monitoreoActividadentity = {
      monitoreoActividadId: this.state.monitoreo.monitoreoId,
      productoId: this.state.monitoreo.productoId,
      actividadId: this.state.monitoreo.actividadId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      trimestre: this.state.monitoreo.trimestre,
      comentarioHistorial:
        "El monitoreo de la actividad " +
        this.state.monitoreo.actividad +
        " fue devuelto debido a que " +
        comentarioHistorial,
    };

    await this.props.updateMonitoreoActividad(monitoreoActividadentity);
    this.setState({ isLoadingState: false });
  };

  render() {
    const {
      isLoading,
      monitoreosActividad,
      monitoreo,
      actividadId,
      poaMonitoreo,
      isLoadingMonitoreo,
      monitoreoMediosVerificacion
    } = this.props;

    return (
      <React.Fragment>
        <Link
          className="btn  btn-info float-right mb-3"
          //to={`/admin/monitoreo/actividad/${actividadId}/nuevo`}
          to={{
            pathname: `/admin/monitoreo/actividad/${actividadId}/nuevo`,
            state: "/admin/monitoreo/actividad/:actividadId/nuevo",
          }}
        >
          Agregar Monitoreo
        </Link>
        {isLoading || isLoadingMonitoreo ? (
          <Loading></Loading>
        ) : !isLoading && monitoreo.length > 0 ? (
          <Table
            style={{ backgroundColor: "#fff", color: "black" }}
            responsive
            className="table-striped"
          >
            <thead>
              <tr className="">
                <th>Trimestre</th>
                <th>Nivel Ejecución</th>
                <th>Fecha</th>
                <th>Archivo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {monitoreo.map((monitoreo) => (
                <Monitoreo
                  activarModalArchivos={this.activarModalArchivos}
                  activarModalDevolucionMonitoreo={
                    this.activarModalDevolucionMonitoreo
                  }
                  enviarMonitoreo={this.enviarMonitoreo}
                  devolverMonitoreo={this.devolverMonitoreo}
                  poaMonitoreo={poaMonitoreo}
                  key={monitoreo.monitoreoId}
                  monitoreoActividad={monitoreo}
                  monitoreoMediosVerificacion ={monitoreo.monitoreoMediosVerificacion}
                  actividadId={actividadId}
                  user={this.props.user}
                ></Monitoreo>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="row d-flex justify-content-center mb-3">
            <h5>No tiene Monitoreo de Actividades agregadas</h5>
          </div>
        )}
        <ModalArchivos
          activarModalArchivos={this.activarModalArchivos}
          state={this.state}
          modalArchivos={this.state.modalArchivos}
          monitoreoMediosVerificacion={this.state.monitoreoMediosVerificacion}
          justificacionDeNoCumplimiento={this.state.justificacionDeNoCumplimiento}
          deletMonitoreoMedioVerificacion={
            this.props.deletMonitoreoMedioVerificacion
          }
          getReporteEvidenciaNoCumplimiento={this.props.getReporteEvidenciaNoCumplimiento}
          deletMonitoreoEvidencia={this.props.deletMonitoreoEvidencia}
          actividadId={this.props.actividadId}
          statusEliminarArchivo={this.state.statusEliminarArchivo}
        ></ModalArchivos>
        <ModalDevolucionMonitoreo
          activarModalDevolucionMonitoreo={this.activarModalDevolucionMonitoreo}
          modalDevolucion={this.state.modalDevolucion}
          monitoreo={this.state.monitoreo}
          devolverMonitoreo={this.devolverMonitoreo}
        ></ModalDevolucionMonitoreo>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  monitoreosActividad: state.monitoreosActividad.monitoreosActividad,
  isLoading: state.actividades.isLoading,
  user: state.auth.signed,
  producto: state.productos.producto,
  isLoadingMonitoreo: state.monitoreosActividad.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getMonitoreosActividad: (actividadId) =>
    dispatch(getMonitoreosActividad(actividadId)),
  updateMonitoreoActividad: (monitoreoActividad) =>
    dispatch(updateMonitoreoActividad(monitoreoActividad)),
  deletMonitoreoMedioVerificacion: (actividadId, medioVerificacionId) =>
    dispatch(deletMonitoreoMedioVerificacion(actividadId, medioVerificacionId)),
  getReporteEvidenciaNoCumplimiento: (c1, c2, fileName, nombreImagen) => dispatch(getReporteEvidenciaNoCumplimiento(c1, c2, fileName, nombreImagen)),
  deletMonitoreoEvidencia: (actividadId, evidenciaId) =>
    dispatch(deletMonitoreoEvidencia(actividadId, evidenciaId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaMonitoreosActividad, [
    "Visualizar Productos",
    "Visualizar Actividades",
    "Responsables de areas",
    "Responsables de productos",
    "Administrador general",
    "Analista del POA",
    "MAE",
  ])
);
